export default function Ticker() {
  this.clients = [];
  this.running = false;

  this.start = function() {
    this.running = true;
    requestAnimationFrame(this.tick.bind(this));
  }
  this.stop = function() {
    this.running = false;
  }
  this.tick = function() {
    this.notifyClients();
    if (this.running) {
      requestAnimationFrame(this.tick.bind(this));
    }
  }
  this.notifyClients = function() {
    for (let client of this.clients) {
      client.receiveNotification(null, 'ticker');
    }
  }
  this.addClient = function(client) {
    this.clients.push(client);
  }
}
