<template>
  <div
      id="app"
      :class="[
          $route.name+'-page',
          {'cabinet-page' : isCabinetPages}
      ]"
  >
    <div class="header" :class="{'header_menu-opened': isMenuOpened, '_no-bg': !hideProductLinks}">
      <div class="container">
        <div class="header__nav" v-if="isArchivePage && isAuthenticated && !isHomePage">
          <router-link
            v-for="link in portalLinks"
            :key="link.to"
            :to="{ name: link.to }"
            class="header__nav-item"
          >{{link.name}}
          </router-link>
        </div>
        <div class="header__nav" v-else-if="isAuthenticated && isPageForMainAndBrandLinks">
          <router-link
            v-for="link in mainLinks"
            :key="`${link.path}main`"
            :to="{ path: link.path }"
            class="header__nav-item header__nav-item_additional header__nav-item_no-underline"
          >{{link.name}}</router-link>
          <router-link
            v-for="link in brandLinks"
            :key="`${link.path}brand`"
            :to="{ path: link.path }"
            class="header__nav-item"
          >{{link.name}}</router-link>
        </div>
        <template v-else-if="isAuthenticated && isHomePage">
          <div class="header__nav">
            <router-link
              v-for="link in mainLinks"
              :key="link.path"
              :to="{ path: link.path }"
              class="header__nav-item header__nav-item_no-underline"
            >{{link.name}}</router-link>
          </div>
        </template>
        <div
          v-if="isAuthenticated && !isPortalPage"
          class="header__burger"
          @click="toggleMenu"
        >
          <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="header__burger-icon" v-if="isHomePage">
            <path d="M1 1H17M1 7H17M1 13H17" stroke="#1E418E" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span class="header__burger-icon" />
        </div>
        <div class="header__user-btns">
          <router-link v-if="isAuthenticated && !isProfilePage" :to="{ name: 'profile' }" class="header__profile"/>
          <a v-if="currentUser.sessid" href="#" class="header__logout" @click.prevent="logout"></a>
        </div>
      </div>
    </div>

    <template v-if="userUpdated">
      <template v-if="registerBg">
        <div class="register">
          <ajax-loader v-show="isLoading"/>

          <errors-bag v-show="showErrorsBag"/>

          <router-view />
        </div>
      </template>
      <template v-else>
        <ajax-loader v-show="isLoading"/>

        <errors-bag v-show="showErrorsBag"/>

        <router-view />
      </template>
    </template>

    <update-personal v-if="showUpdateProfile || showUpdateEmail" />

    <modal
      :show.sync="isVisibleInfoModal"
      v-if="isAuthenticated"
      class="modal-info"
      @modal:close="closeInfoModal"
    >
      <div class="modal-info__wrapper">
        <div class="modal-info__title form__title">
          Уважаемые пользователи!
        </div>
        <div class="modal-info__description">
          С&nbsp;1&nbsp;февраля 2024 года бонусная программа на&nbsp;сайте mevius.ru перестанет работать.
          <br><br>
          Все победители бонусной программы за&nbsp;2023 год получат сертификаты и&nbsp;подарки в&nbsp;соответствии с&nbsp;<a class="_link" href="/upload/docs/Terms_and_Conditions_progress.pdf"><strong>условиями программы</strong></a>.
          <br><br>
          Если Вам полагается сертификат или подарок, но&nbsp;Вы не&nbsp;получили его, сообщите нам об&nbsp;этом через <a class="_link" href="https://mevius.ru/feedback"><strong>форму обратной связи</strong></a> или по&nbsp;телефону горячей линии <a class="_tel" href="tel:88002001954">8&nbsp;800 200-19-54</a>.
        </div>
        <div class="modal-info__button">
          <button class="button__blue button__lg" @click.prevent="closeInfoModal">
            Закрыть
          </button>
        </div>
      </div>
    </modal>

    <modal
      :show.sync="isVisibleNewFeaturesModal"
      v-if="isAuthenticated"
      class="modal-info"
      @modal:close="closeNewFeaturesModal"
    >
      <div class="modal-info__wrapper">
        <div class="modal-info__title form__title">
          Познакомьтесь с&nbsp;обновленными разделами сайта о&nbsp;продукции Mevius
        </div>
        <div class="modal-info__links">
          <router-link :to="{ path: '/' }" @click.native="closeNewFeaturesModal">
            <span class="_icon">
              <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.43608 13.6169L15.4361 1.92012C16.933 0.668555 19.0658 0.668555 20.5641 1.92012L34.5641 13.6169C35.4875 14.3888 36 15.4826 36 16.6841V31.0185C36 33.2232 34.2047 35.0185 32 35.0185H4C1.80156 35.0185 0 33.217 0 31.0185V16.6857C0 15.4826 0.51264 14.3904 1.43608 13.6169ZM21.8297 32.6185V23.3701H14.1705V32.6185H21.8297ZM11.7705 32.6185V22.1701C11.7705 21.5076 12.308 20.9701 12.9705 20.9701H23.0297C23.6922 20.9701 24.2297 21.5076 24.2297 22.1701V32.6185H32.0001C32.8798 32.6185 33.6001 31.8982 33.6001 31.0185V16.6857C33.6001 16.1701 33.4204 15.7842 33.0266 15.456L19.0266 3.75924C18.4266 3.25768 17.5735 3.25768 16.9735 3.75924L2.97352 15.456C2.57977 15.7842 2.40008 16.1701 2.40008 16.6857V31.0201C2.40008 31.906 3.11416 32.6201 4.00008 32.6201L11.7705 32.6185Z" fill="#1E418E"/>
              </svg>
            </span>
            <span class="_title">История бренда</span>
          </router-link>
          <router-link :to="{ path: '/products/ssb-sky-blue' }" @click.native="closeNewFeaturesModal">
            <span class="_icon">
              <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.6002 0H10.2714C11.7037 0 12.8716 1.17349 12.8716 2.6002V10.271C12.8716 11.7037 11.704 12.8712 10.2714 12.8712H2.6002C1.88852 12.8712 1.23856 12.5782 0.765595 12.106L0.762992 12.1086C0.292257 11.6382 0.000371624 10.9875 0.000371624 10.2714V2.6002C-2.04269e-07 1.17461 1.17461 0 2.6002 0ZM17.7295 15.1297H25.4007C26.8334 15.1297 28.0009 16.2972 28.0009 17.7299V25.4007C28.0009 26.1124 27.7079 26.7623 27.2357 27.2353L27.2383 27.2379C26.7679 27.7083 26.1172 28.0005 25.4011 28.0005H17.7295C16.3039 28.0005 15.1293 26.8259 15.1293 25.4003V17.7299C15.1297 16.2972 16.3032 15.1297 17.7295 15.1297ZM25.4007 17.7039L17.7039 17.7299L17.7299 25.4267L25.4182 25.4178L25.4208 25.4204L25.4007 17.7039ZM2.6002 15.1297H10.2714C11.704 15.1297 12.8716 16.2972 12.8716 17.7299V25.4007C12.8716 26.1124 12.5786 26.7623 12.1064 27.2353L12.109 27.2379C11.6382 27.7083 10.9879 28.0005 10.2718 28.0005H2.6002C1.17461 28.0005 0 26.8259 0 25.4003V17.7299C0 16.2972 1.17349 15.1297 2.6002 15.1297ZM10.2714 17.7039L2.57454 17.7299L2.60057 25.4267L10.2889 25.4178L10.2915 25.4204L10.2714 17.7039ZM17.7295 0H25.4007C26.833 0 28.0009 1.17349 28.0009 2.6002V10.271C28.0009 11.7037 26.8334 12.8712 25.4007 12.8712H17.7295C17.0178 12.8712 16.3679 12.5782 15.8949 12.106L15.8923 12.1086C15.422 11.6382 15.1297 10.9875 15.1297 10.2714V2.6002C15.1297 1.17461 16.3043 0 17.7295 0ZM25.4007 2.57417L17.7039 2.6002L17.7128 10.2885L17.7102 10.2911L25.4267 10.271L25.4007 2.57417ZM10.2714 2.57417L2.57454 2.6002L2.58346 10.2885L2.58086 10.2911L10.297 10.271L10.2714 2.57417Z" fill="#1E418E"/>
              </svg>
            </span>
            <span class="_title">Продукты</span>
          </router-link>
          <router-link :to="{ name: 'profile' }" @click.native="closeNewFeaturesModal">
            <span class="_icon">
              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 0C11.0211 0 7.2064 1.58 4.3936 4.3936C1.57984 7.20611 0 11.0211 0 15C0 18.9789 1.58 22.7936 4.3936 25.6064C7.20611 28.4202 11.0211 30 15 30C18.9789 30 22.7936 28.42 25.6064 25.6064C28.4202 22.7939 30 18.9789 30 15C29.995 11.0237 28.4138 7.20992 25.6013 4.39872C22.79 1.58621 18.9763 0.00512 15 0ZM15 2C18.2701 1.995 21.4224 3.22499 23.825 5.44384C26.2275 7.66259 27.705 10.7062 27.96 13.9664C28.2162 17.2275 27.2313 20.464 25.2037 23.0301C22.4363 20.4401 18.7887 19 14.9999 19C11.2111 19 7.56374 20.44 4.79606 23.0301C2.76858 20.4638 1.78355 17.2275 2.03981 13.9664C2.29481 10.7062 3.77232 7.66272 6.17485 5.44384C8.57734 3.22509 11.7299 1.9952 15 2ZM6.15136 24.4976C8.55011 22.2501 11.7139 21 15 21C18.2861 21 21.4499 22.25 23.8486 24.4976C21.4511 26.7476 18.2874 28 15 28C11.7126 28 8.5488 26.7475 6.15136 24.4976ZM15 16C16.3262 16 17.5975 15.4738 18.535 14.535C19.4738 13.5975 20 12.3263 20 11C20 9.67366 19.4738 8.40246 18.535 7.46493C17.5975 6.52618 16.3263 5.99994 15 5.99994C13.6737 5.99994 12.4025 6.52618 11.465 7.46493C10.5262 8.40243 9.99997 9.67366 9.99997 11C10.0012 12.325 10.5287 13.5962 11.4662 14.5337C12.4037 15.4712 13.675 15.9988 15 16ZM15 8C16.2138 8 17.3075 8.73126 17.7713 9.85251C18.2363 10.9725 17.9788 12.2638 17.1213 13.1213C16.2638 13.9788 14.9725 14.2363 13.8525 13.7713C12.7312 13.3076 12 12.2138 12 11C12.0012 9.34378 13.3437 8.00125 15 8Z" fill="#1E418E"/>
              </svg>
            </span>
            <span class="_title">Личный кабинет</span>
          </router-link>
        </div>
      </div>
    </modal>

    <cookie-law theme="banner" buttonClass="button button__md button__blue" buttonText="Понятно" storageType="cookies" storageName="cookie">
      <div slot="message">
        Мы используем <a href="/upload/docs/polit_konf.pdf" target="_blank">Cookies</a> и сервис веб-аналитики Яндекс Метрика для правильной работы сайта.
      </div>
    </cookie-law>

    <footer-component
      :class="{'_g-hidden-mobile': $route.name === 'products-detail'}"
      :has-copy="isHomePage"
    />
  </div>
</template>

<script>
import AjaxLoader from './components/AjaxLoader';
import ErrorsBag from './components/ErrorsBag';
import FooterComponent from './components/ui/FooterComponent';
import CookieLaw from 'vue-cookie-law';
import UpdatePersonal from './components/UpdatePersonal.vue';
import Modal from './components/ui/Modal.vue';

export default {
  name: 'App',
  components: {
    AjaxLoader,
    ErrorsBag,
    FooterComponent,
    CookieLaw,
    UpdatePersonal,
    Modal
  },
  data() {
    return {
      timeInMs: 5000,
      userUpdated: false,
      showUpdateProfile: false,
      brandLinks: [
        {path: '/products/lss-original', name: 'Original'},
        {path: '/products/lss-sky-blue', name: 'Sky Blue'},
        {path: '/products/ssb-sky-blue', name: 'Sky Blue Side-Slide'},
        {path: '/products/ssb-wind-blue', name: 'Wind Blue Side-Slide'},
        // {path: '/products/compact-crush', name: 'compact crush'},
      ],
      portalLinks: [
        {to: 'archive-articles', name: 'статьи'},
        {to: 'archive-tests', name: 'тесты'},
        {to: 'archive-surveys', name: 'опросы'},
      ],
      mainLinks: [
        {path: '/', name: 'История бренда'},
        {path: '/products/ssb-sky-blue', name: 'Продукты Mevius'},
      ],
      showUpdateEmail: false,
      isVisibleInfoModal: false,
      isVisibleNewFeaturesModal: false
    }
  },
  computed: {
    hideProductLinks() {
      return this.$route.name !== 'register'
        && this.$route.name !== 'login'
        && this.$route.name !== 'reset'
        && !(this.$route.name === 'feedback' && !this.isAuthenticated )
    },
    isPortalPage() {
      if (this.$route.name) {
        return (this.$route.name.indexOf('portal') !== -1);
      } else return false
    },
    isArchivePage() {
      if (this.$route.name) {
        return (this.$route.name.indexOf('archive') !== -1);
      } else return false
    },
    isCabinetPages() {
      if (this.$route.name) {
        return (this.$route.path.indexOf('cabinet') !== -1);
      } else return false
    },
    isHistoryPage() {
      if (this.$route.name) {
        return (this.$route.path.indexOf('history') !== -1);
      } else return false
    },
    isPageForMainAndBrandLinks() {
      return (!this.isHomePage && !this.isPortalPage) || this.isCabinetPages;
    },
    registerBg() {
      return this.$route.name === 'register'
    },
    isMenuOpened() {
      return this.$store.state.isMenuOpened;
    },
    isProfileMenuOpened() {
      return this.$store.state.isProfileMenuOpened;
    },
    isLoading() {
      return this.$store.state.isLoading;
    },
    isAuthenticated() {
      return this.$store.state.isAuthenticated;
    },
    showErrorsBag() {
      if (this.$store.state.errors.find((item) => item === 'Email already used')) {
        return false;
      }
      return this.$store.state.showErrorsBag;
    },
    currentUser() {
      return this.$store.state.currentUser;
    },
    logoutUrl() {
      return `/?logout=yes&sessid=${this.currentUser.sessid}`;
    },
    isBanned() {
      return this.$store.state.userBanned;
    },
    isProfilePage() {
      return /profile/i.test(this.$route.name);
    },
    isHomePage() {
      return /home/i.test(this.$route.name);
    },
    isShowLogo() {
      return this.$route.name !== 'login' && this.$route.name !== 'register' && this.$route.name !== 'reset';
    },
    profileLinks() {
      const segments = this.$store.state.userSegments;
      const profileLinks = [
        //{path: '/products/compact-crush', name: 'Продукты'},
        {to: 'profile', name: 'Личные данные'},
        {to: 'activity', name: 'Прогресс'},
        {to: 'profile-documents', name: 'Документы'},
        {to: 'profile-password', name: 'Смена пароля'},
        {to: 'profile-delete', name: 'Действия с анкетой'},
        {to: 'profile-subscribe', name: 'подписки'},
      ];

      if (!!segments.alpha || !!segments.betta) {
        profileLinks.splice(2, 0, {to: 'profile-update', name: 'Обновление данных'})
      }

      return profileLinks;
    },
    getProfileData() {
      return this.$store.state.profileData ? this.$store.state.profileData : false;
    }
  },
  watch: {
    '$route'() {
      this.$store.commit('setIsMenuOpened', false);
      this.showUpdateProfile = this.isAuthenticated
        && (this.$route.name !== 'profile-update')
        && this.$route.name !== 'profile';
      this.showUpdateEmail = this.showUpdateProfile && !this.getProfileData.email;
    },
    isAuthenticated(val) {
      if (val) {
        this.isVisibleNewFeaturesModal = true;
      }
    }
  },
  async created() {
    /*
    const auth = await this.$store.dispatch('checkAuthentication');
    if (!auth) {
      this.logout();
    }
    */
    this.userUpdated = true;
    this.getProfile()
  },
  methods: {
    closeInfoModal() {
      localStorage.setItem('infoModalHidden', true);
      this.isVisibleInfoModal = false;
    },
    closeNewFeaturesModal() {
      sessionStorage.setItem('newFeaturesModalHidden', true);
      this.isVisibleNewFeaturesModal = false;
    },
    toggleMenu() {
      //this.$store.commit('setIsProfileMenuOpened', false);
      this.$store.dispatch('toggleMenu');
    },
    toggleProfileMenu() {
      this.$store.commit('setIsMenuOpened', false);
      this.$store.dispatch('toggleProfileMenu');
    },
    logout() {
      this.$store.dispatch('logout', this.logoutUrl);
    },
    getProfile() {
      this.$store.dispatch('fetchProfile');
    }
  },
  mounted() {
    if(sessionStorage.getItem('newFeaturesModalHidden') === null) {
      this.isVisibleNewFeaturesModal = true;
    }
    setTimeout(()=> {
      if (localStorage.getItem('infoModalHidden') === null) {
        //this.isVisibleInfoModal = true;
      }
    }, this.timeInMs);
  }
}
</script>
