// collects distance traveled by cursor and converts it into rotation speed
export default function Rotator() {

  const initialHeight = 678;
  const initialModifier = .009;
  let distance = 0;
  let step = 0;

  let modifier = .009;

  const zeroCut = .01;
  const clients = [];

  this.receiveNotification = function(value, sender) {
    switch (sender) {
      case 'swiper':
        distance += value;
        break;
      case 'ticker':
        if (distance !== 0) {
          distance = reduce(distance);
          if (distance === 0) {
            return;
          }
          step = distance * .1;
          notifyClients();
          distance -= step;
        }
        break;
    }
  }

  this.receiveHeight = function(height) {
    modifier = initialHeight / height * initialModifier;
  }

  const reduce = function(value) {
    if (value < zeroCut && value > -zeroCut) {
      return 0;
    }
    return value;
  }

  const notifyClients = function() {
    if (step == 0) return
    for (let client of clients) {
      client.receiveNotification(step * modifier, 'rotator');
    }
  }
  this.addClient = function(client) {
    clients.push(client);
  }
}
