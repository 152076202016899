<template>
  <form v-if="phoneValidate">
    <h2 class="form__title">Регистрация</h2>
    <h3 class="form__subtitle">3 шаг. Подтверждение номера<br> мобильного телефона</h3>
    <div class="form__description">
      <p>
        Для продолжения регистрации позвоните с&nbsp;номера <b class="_g-text-no-wrap">{{ phone }}</b>
        по&nbsp;номеру, указанному ниже. Звонок бесплатный.
        Подтверждение Вашего номера телефона произойдет автоматически в&nbsp;течение пяти секунд после звонка.
      </p>

      <h2>
        <a class="form__link" :href="`tel:+${phoneValidate.data.callDetails.confirmationNumber}`">
          {{ phoneValidate.data.callDetails.confirmationNumber | VMask('+# (###) ###-##-##') }}
        </a>
      </h2>

      <p>
        Обратите внимание, что&nbsp;аккаунт с&nbsp;неподтвержденным
        номером телефона будет удален в&nbsp;течение <b>24&nbsp;часов</b>
      </p>
    </div>

    <div class="form-row form-row_mobile-wrap form__actions">
      <div class="form__nav">
        <p>
          Если звонок не проходит, вы можете <br> запросить другой номер для&nbsp;звонка
          <span v-if="resendTimeLeft > 0">через&nbsp;<b>{{ resendTimeLeft }}&nbsp;сек</b></span>
        </p>
      </div>
      <button class="form__submit button button__lg button__blue"
              type="submit"
              :disabled="loading || resendTimeLeft > 0"
              @click="verifyPhone"
      >
        запросить заново
      </button>
    </div>

    <div class="form-row form-row_mobile-wrap">
      <div class="form__nav">
        <router-link v-if="!$store.state.currentUser" :to="{name: 'login'}" class="form__link">Войти</router-link>
        <router-link :to="{ name: 'feedback' }" class="form__link">Написать нам</router-link>
        <delete-questionnaire-btn />
      </div>
      <div class="form__nav form__nav-first">
        <a href="#" class="form__link" @click.prevent="goBack">Изменить номер</a>
      </div>
    </div>
  </form>
</template>

<script>
import api from "../services/api";

const REFRESH_TIMEOUT = 60

export default {
  name: "SignUpStep3",
  props: {
    phone: {type: String}
  },
  data() {
    return {
      phoneValidate: null,
      resendTimeLeft: 0,
      checkInterval: null,
      loading: false
    }
  },
  methods: {
    async phoneByCallCheck() {
      try {
        if (this.resendTimeLeft % 5 === 0) {
          const callId = await api.verifyPhoneByCallCheck(this.phoneValidate?.data?.callDetails?.callId);
          if (callId) {
            this.stopTimer();
            this.$emit('complete');
            this.$store.dispatch('hideErrorsBag');
          }
        }

        if (this.resendTimeLeft > 0) {
          this.resendTimeLeft--;
        } else {
          this.stopTimer();
        }
      } catch (e) {
        this.$store.dispatch('setErrors', e);
      }
    },
    async verifyPhone() {
      if (this.loading || this.resendTimeLeft > 0) {
        return
      }
      this.loading = true

      try {
        this.phoneValidate = await api.verifyPhoneByCall(String(this.phone.replace(/\D/g, "")));
        this.startTimer();
      } finally {
        this.loading = false
      }
    },
    startTimer() {
      this.stopTimer();
      this.resendTimeLeft = REFRESH_TIMEOUT;
      this.checkInterval = setInterval(this.phoneByCallCheck, 1000);
    },
    stopTimer() {
      this.resendTimeLeft = 0;
      clearInterval(this.checkInterval);
    },
    goBack() {
      this.$emit('back');
    }
  },
  async created() {
    await this.verifyPhone();
  },
  beforeDestroy() {
    this.stopTimer();
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/base/_settings.scss";
.form__actions {
  gap: 10px;
  @include mobile {
    text-align: center;
  }
}
.form__nav-first {
  @include mobile {
    order: 1;
  }
}
.form__submit {
  white-space: nowrap;
}
</style>
