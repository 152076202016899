<template>
  <div class="fileinput" ref="fileDrop"
      :class="{empty: !value}"
       @dragenter.prevent="highlight"
       @dragover.prevent="highlight"
       @dragleave.prevent="unhighlight"
       @drop.prevent="handleDrop($event)"
  >
    <h3 class="fileinput__title">
      <slot name="title"/>
    </h3>
    <!-- a href="" v-if="value != ''" class="fileinput__delete" @click.prevent="clearInput" title="Удалить"></a-->
    <label>
      <input type="file" :name="inputName" @change.prevent="getInputValue" ref="fileInput">
      <span class="fileinput__button">Выбрать файл</span>
      <span class="fileinput__label">
        <template v-if="value === ''">Файл не выбран</template>
        <template v-else>{{ value }}</template>
      </span>
    </label>
    <slot name="errors" v-bind="{ sizeError, extensionError }" />
  </div>
</template>

<script>
export default {
  name: 'FileInput',
  props: {
    inputTitle: {
      type: String,
      default: '',
    },
    inputName: {
      type: String,
      default: '',
    },
    maxSize: {
      type: Number,
      default: 10 * 1024 * 1024
    },
    allowedExtensions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      value: '',
      file: null
    }
  },
  methods: {
    getInputValue() {
      this.file = this.$refs.fileInput.files[0];

      if (!this.sizeError && !this.extensionError) {
        this.value = this.$refs.fileInput.value.replace(/C:\\fakepath\\/i, '');
        this.$emit('change', this.file);
      } else {
        this.clearInput();
        return false;
      }
    },
    clearInput() {
      this.value = '';
      this.$refs.fileInput.value = '';
    },
    handleDrop(e) {
      this.unhighlight();
      let dt = e.dataTransfer;
      this.file = dt.files[0];

      if (!this.sizeError && !this.extensionError) {
        this.value = this.file.name;
        this.$emit('change', this.file);
      } else {
        this.clearInput();
        return false;
      }
    },
    highlight() {
      this.$refs.fileDrop.classList.add('_highlight');
    },
    unhighlight() {
      this.$refs.fileDrop.classList.remove('_highlight');
    }
  },
  computed: {
    sizeError() {
      if (!this.file) return false;
      return this.file.size > this.maxSize;
    },
    extensionError() {
      if (!this.file) return false;
      if (!this.allowedExtensions.length) return false;
      const list = this.file.name.split('.');
      const extension = list[list.length - 1];
      if (!extension) return true;
      return !this.allowedExtensions.some(element => element === extension);
    }
  },
}
</script>
