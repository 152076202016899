<template>
  <div class="radio-input" :class="{_disabled: disabled}">
    <input
      :id="id"
      class="radio-input__field"
      type="radio"
      :tabindex="tabindex"
      :value="value"
      :name="name"
      :checked="shouldBeChecked"
      :disabled="disabled"
      @change="emitChange"
    >
    <label
      class="radio-input__label"
      :for="id"
      v-html="label"
    />
  </div>
</template>

<script>
let radioInputId = 0;

export default {
  name: "RadioInput",
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      default: () => {
        return 'radio-input-' + radioInputId++;
      },
    },
    tabindex: {
      type: [String, Number]
    },
    value: {
      type: [String, Boolean],
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [String, Boolean],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    shouldBeChecked() {
      return this.modelValue === this.value;
    },
  },
  methods: {
    emitChange() {
      this.$emit('change', this.value);
    },
  },
}
</script>

<style lang="scss">
@import "../scss/base/_settings.scss";
.radio-input {
  &._disabled {
    .radio-input__label {
      cursor: default;
    }
  }
  &__field {
    display: none;

    &:checked + label::before {
      background-color: $brand-color;
      background-clip: content-box;
    }
  }

  &__label {
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;

    &::before {
      content: "";
      width: 17px;
      height: 17px;
      margin-right: 10px;
      box-sizing: border-box;
      padding: 3px;
      border: 1px solid $input-border;
      border-radius: 50%;
      flex-shrink: 0;
    }
  }

  &__error {
    font-size: 11px;
    line-height: 1.4;
    color: $error-text;
    margin-bottom: -15px;
    width: 100%;
    position: absolute;
    top: 27px;
    @include mobile {
      top: 17px;
    }
  }
}
</style>
