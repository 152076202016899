import * as THREE from 'three';

export const VctrApi = function() {

  this.setCamera = function(camera) {
    this.camera = camera;
  }

  this.setModel = function(model) {
    this.model = model;
  }

  this.setProductPage = function(productPage) {
    this.productPage = productPage;
  }

  this.getViewState = function() {
    return this.currentViewState;
  }
  this.getRotation = function(objectName) {
    const object = this.getModelChildByName(objectName);
    return [
      VctrApi.Utils.radiansToDegrees(object.rotation.x),
      VctrApi.Utils.radiansToDegrees(object.rotation.y),
      VctrApi.Utils.radiansToDegrees(object.rotation.z)
    ];
  }
  this.getPosition = function(objectName) {
    const object = this.getModelChildByName(objectName);
    return [ object.position.x, object.position.y, object.position.z ];
  }

  this.setPositionAbsolute = function(objectName, value) {
    const object = this.getModelChildByName(objectName);
    object.position.set(
      value[0],
      value[1],
      value[2]
    );
  }

  this.setRotationAbsolute = function(objectName, value) {
    const object = this.getModelChildByName(objectName);
    object.rotation.set(
      VctrApi.Utils.degreesToRadians(value[0]),
      VctrApi.Utils.degreesToRadians(value[1]),
      VctrApi.Utils.degreesToRadians(value[2])
    );
  }

  this.applyViewState = function(state) {
    this.setCurrentViewState(state); // save state to store data for later use
    this.camera.position.set(state.position[0], state.position[1], state.position[2]);
    this.camera.lookAt(new THREE.Vector3(state.target[0], state.target[1], state.target[2]));
    this.camera.zoom = state.zoom;
    this.camera.updateProjectionMatrix();
    this.productPage.dismissApplyViewStateOnTick();
  }

  this.setCurrentViewState = function(state, options = { updatedRotation: false }) {
    this.currentViewState =
      options.updatedRotation ?
      VctrApi.Utils.recalculateViewStatePosition(state) :
      VctrApi.Utils.recalculateViewStateAngleAndDistance(state)
  }

  this.getModelChildByName = function(objectName) {
    let childMatching;
    this.model.traverse(child => {
      if (child.name === objectName) {
        childMatching = child;
      }
    });
    return childMatching
  }
}

VctrApi.Utils = {

  animate(label, durationMs, easing, callback, finishCallback) {
    VctrApi.Animator.registerAnimation(label, durationMs, easing, callback, finishCallback);
  },

  lerp(array0, array1, t) {
    return array0.map((value0, index) => VctrApi.Utils.interpolateValues(value0, array1[index], t));
  },

  lerpTwoValues(value0, value1, t) {
    return VctrApi.Utils.interpolateValues(value0, value1, t);
  },

  interpolateValues(value0, value1, t) {
    return value0 + (value1 - value0) * t;
  },

  degreesToRadians(degrees) {
    return degrees * Math.PI / 180;
  },

  radiansToDegrees(radians) {
    return radians * 180 / Math.PI;
  },

  // get polar coordinates of camera on xz plane based on camera position
  recalculateViewStateAngleAndDistance(state) {
    const x = state.position[0] - state.target[0];
    const z = state.position[2] - state.target[2];
    state.distance = Math.sqrt(x * x + z * z);
    state.angle = Math.atan2(z, x);
    return state;
  },

  // get position of camera based on polar coordinates of camera on xz plane
  recalculateViewStatePosition(state) {
    const x = Math.cos(state.angle) * state.distance;
    const z = Math.sin(state.angle) * state.distance;
    state.position[0] = x + state.target[0];
    state.position[2] = z + state.target[2];
    return state;
  }
}

VctrApi.Animator = new Animator();

function Animator() {
  this.availableAnimationId = 0;
  this.animations = {};
  this.id = Math.round(Math.random() * 10000);

  this.registerAnimation = function(label, durationMs, easing, callback, finishCallback) {
    const startingTime = performance.now();
    this.animations[this.availableAnimationId] = {
      label, durationMs, easing, callback, finishCallback, startingTime
    }
    this.availableAnimationId ++;
  }

  this.deleteAllAnimations = function() {
  }

  this.handleAnimationFinish = function(id) {
    const animation = this.animations[id];
    if (animation.finishCallback) {
      animation.finishCallback(animation.label);
    }
    this.deleteAnimation(id);
  }

  this.deleteAnimation = function(id) {
    delete this.animations[id];
  }

  this.tick = function() {
    const currentTime = performance.now();
    const ids = Object.keys(this.animations);
    for (let id of ids) {
      const animation = this.animations[id];
      let t = (currentTime - animation.startingTime) / animation.durationMs;
      if (t > 1) {
        t = 1;
      }
      animation.callback(this.ease(t, animation.easing));
      if (t === 1) {
        this.handleAnimationFinish(id);
      }
    }
  }

  this.ease = function(x, easingType) {
    if (easingType === 'easeInOutQuad') {
      return x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2;
    } else {
      return x;
    }
  }
}