<template>
  <div class="feedback-form">
    <my-modal>
      <form v-if="!sent" class="form" @submit.prevent="submit">
        <h2 class="form__title">Служба поддержки</h2>
        <div class="form__description form__description_align-center">
          <p>
            Телефон горячей линии <strong>8 800 200-19-54</strong>
            <br>
            Звонок по России с мобильных телефонов бесплатный, или напишите нам о проблеме, и специалисты свяжутся с вами. Ответ поступит на указанный адрес электронной почты.
          </p>
        </div>
        <div class="form-row form-row_2-cols form-row_mobile-no-flex">
          <text-input
            label="Электронная почта *"
            v-model="$v.form.email.$model"
            name="email"
            :error="$v.form.email.$error"
          >
            <template #errors>
              <div class="text-input__error" v-if="$v.form.email.$error">
                <div v-if="!$v.form.email.required">Заполните поле</div>
                <div v-if="!$v.form.email.email">Неверный формат электронной почты</div>
              </div>
            </template>
          </text-input>
          <text-input
            label="Имя и фамилия *"
            class="text-input__cyrillic"
            name="name"
            tabindex="1"
            v-model="$v.form.name.$model"
            :error="$v.form.name.$error"
          >
            <template #errors>
              <div class="text-input__error" v-if="$v.form.name.$error">
                <div v-if="!$v.form.name.required">Заполните поле</div>
              </div>
            </template>
          </text-input>
        </div>
        <div class="form-row form-row_no-flex">
          <custom-select
            label="Тема сообщения *"
            v-model="$v.form.subject.$model"
            :options="subjectOptions"
            :error="$v.form.subject.$error"
          >
            <template #errors>
              <div class="select__error" v-if="$v.form.subject.$error">
                <div v-if="!$v.form.subject.required">Выберите тему</div>
              </div>
            </template>
          </custom-select>
        </div>
        <div class="form-row form-row_no-flex">
          <text-input
            label="Текст сообщения *"
            input-tag="textarea"
            rows="6"
            v-model="$v.form.message.$model"
            name="message"
            :error="$v.form.message.$error"
          >
            <template #errors>
              <div class="text-input__error" v-if="$v.form.message.$error">
                <div v-if="!$v.form.message.required">Заполните поле</div>
                <div v-else-if="!$v.form.message.minLength">
                  Минимальное кол-во символов {{ $v.form.message.$params.minLength.min }}
                </div>
                <div v-else-if="!$v.form.message.maxLength">
                  Превышено максимально допустимое кол-во символов
                </div>
              </div>
            </template>
          </text-input>
          <div class="form-row form-row_feedback-message">Максимально допустимое кол-во символов: {{ messageMaxLength }}</div>
        </div>
        <div class="form-row form-row_no-flex">
          <multiple-files-input
            input-name="file"
            @change="onChangeFiles"
            :max-size="10 * 1024 * 1024"
            :min-size="50 * 1024"
            :max-amount="3"
            :allowed-extensions="['jpg', 'png', 'gif', 'bmp']"
          >
            <template #title>
              Вы можете прикрепить до трех файлов. Допустимый формат файла JPG, PNG, GIF, BMP (размер не менее 50 Кб и не более 10 Мб).
            </template>
            <template #errors="{ minSizeError, maxSizeError, extensionError, maxAmountError }">
              <div class="fileinput__error fileinput__error_feedback fileinput-multiple__error">
                <div v-if="maxAmountError">Добавлено максимальное количество файлов</div>
                <div v-else-if="extensionError && minSizeError">Недостаточный размер файла, неправильный формат файла</div>
                <div v-else-if="extensionError && maxSizeError">Превышен размер файла, неправильный формат файла</div>
                <div v-else-if="minSizeError">Недостаточный размер файла</div>
                <div v-else-if="maxSizeError">Превышен размер файла</div>
                <div v-else-if="extensionError">Неправильный формат файла</div>
              </div>
            </template>
          </multiple-files-input>
        </div>
        <captcha-input
          v-if="showCaptcha"
          ref="captchaInput"
          v-model="$v.form.captcha.$model"
          :error="$v.form.captcha.$error"
          :sid="captchaSid"
          @requestUpdate="updateCaptchaOnRequest"
        />
        <div>
          <check-input-personal-data-consent
            v-show="!isAuthenticated"
            v-model="$v.form.personalDataConsent.$model"
            :error="$v.form.personalDataConsent.$error"
            name="personalDataConsent"
          />
        </div>
        <div class="form__error-alert" v-show="hasError">
          Пожалуйста, проверьте правильность заполнения формы
        </div>
        <div class="feedback-form__footer">
          <button
            class="feedback-form__submit button button__lg button__blue"
            type="submit"
          >
            Отправить
          </button>
          <ul v-if="!isBanned && !isAuthenticated" class="feedback-form__links">
            <li>
              Уже зарегистрировались?
              <router-link :to="{ name: 'login' }" class="form__link">Войти</router-link>
            </li>
            <li>
              Нет аккаунта?
              <router-link :to="{ name: 'register' }" class="form__link">Зарегистрироваться</router-link>
            </li>
          </ul>
        </div>
      </form>
      <div v-else class="form">
        <h2 class="form__title">Успешно!</h2>
        <div class="form__description form__description_feedback">
          <p>
            Ваше сообщение отправлено в службу поддержки
          </p>
        </div>
        <ul class="feedback-form__links">
          <li>
            <a href="#" class="form__link" @click.prevent="sendNewRequest">Отправить ещё одно обращение</a>
          </li>
          <li>
             <router-link :to="{ path: '/' }" class="form__link">На главную</router-link>
          </li>
        </ul>
      </div>
    </my-modal>
  </div>
</template>

<script>
import { required, email, minLength, maxLength } from "vuelidate/lib/validators";
import MyModal from "./MyModal";
import TextInput from "./TextInput";
import CaptchaInput from "./CaptchaInput";
import MultipleFilesInput from './MultipleFilesInput';
import api from "../services/api";
import CustomSelect from "./CustomSelect";
import invisibleCaptcha from "../mixins/invisibleCaptcha";

export default {
  name: "FeedbackForm",
  mixins: [invisibleCaptcha],
  components: {
    CustomSelect,
    MyModal,
    TextInput,
    CaptchaInput,
    MultipleFilesInput
  },
  props: {
    subjects: {
      type: Array,
      default: () => [
        {
          value: "1",
          name: "О продукте"
        },
        {
          value: "2",
          name: "Регистрация"
        },
        {
          value: "3",
          name: "Восстановление пароля"
        },
        {
          value: "4",
          name: "Отписка от рассылок"
        },
        {
          value: "5",
          name: "Заблокированная учетная запись"
        },
        {
          value: "6",
          name: "Предложения и пожелания"
        },
        {
          value: "8",
          name: "Хочу поменять персональные данные"
        },
        {
          value: "7",
          name: "Другие вопросы по сайту"
        }
      ]
    }
  },
  data() {
    return {
      hasError: false,
      sent: false,
      timer: null,
      form: {
        email: "",
        name: "",
        subject: "",
       // files: [],
        message: "",
        captcha: {
          sid: "",
          code: "",
        },
        personalDataConsent: false,
      },
      messageMaxLength: 1000
    };
  },
  computed: {
    subjectOptions() {
      return [
        ...this.subjects.map(item => ({ value: item.value, name: item.name }))
      ];
    },
    isBanned() {
      return this.$store.state.userBanned;
    },
    isAuthenticated() {
      return this.$store.state.isAuthenticated;
    },
    profileData() {
      return this.$store.state.profileData;
    },
  },
  validations() {
    const form = {
      email: { required, email },
      name: { required },
      subject: { required },
      message: { required, minLength: minLength(15), maxLength: maxLength(this.messageMaxLength) },
      personalDataConsent: { checked: value => value === true }
    }
    this.addCaptchaValidationIfNeeded(form);
    return { form };
  },
  methods: {
    onChangeFiles(files) {
      this.form.files = files;
    },
    async submit() {
      // implementing invisible captcha
      await this.updateCaptchaOnSubmit();

      this.$v.form.$touch();
      this.hasError = this.$v.form.$invalid;

      if (!this.$v.form.$pending && !this.$v.form.$invalid) {
        // TODO: Перенести эту строку в момент получения статуса об успешной отправке
        this.sent = true;

        // this.$store.dispatch("showLoader");

        try {
          const result = await api.feedback(this.form);
          if (result) {
            this.$store.dispatch("hideErrorsBag");
            this.timer = setTimeout(()=>{
              this.$store.dispatch("gohome");
            }, 15000);

          }
        } catch (e) {
          this.$store.dispatch("setErrors", e);
          await this.updateCaptchaOnError();
        } finally {
          this.$store.dispatch("hideLoader");
        }
      }
    },
    reset() {
      this.$v.$reset();
      this.hasError = false,
      this.form.email = (this.profileData && this.profileData.email && this.profileData.email == this.form.email)? this.form.email :  "";
      this.form.name = (this.profileData &&  this.form.name == `${this.profileData.firstName} ${this.profileData.lastName}`)? this.form.name : "";
      this.form.subject = "";
      this.form.file = "";
      this.form.message = "";
      this.form.captcha.sid = "";
      this.form.captcha.code = "";
      this.form.personalDataConsent = this.isAuthenticated ? true : false;
    },
    sendNewRequest() {
      this.reset();
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.sent = false;
    }
  },
  async created() {
    if (this.$store.state.isAuthenticated) {
      await this.$store.dispatch('fetchProfile');
      this.form.name = this.profileData ? `${this.profileData.firstName} ${this.profileData.lastName}` : '';
      this.form.email = this.profileData ? this.profileData.email : '';
      this.form.personalDataConsent = true; // authenticated user already gave personal data consent during registration, so we hide this input and keep the value 'true'
    }
  }
};
</script>

<style lang="scss">
@import "../scss/base/_settings.scss";

$b: ".feedback-form";

#{$b} {
  &__submit {
    margin: 0 auto;

    @include mobile {
      width: 100%;
    }

    &:not(:last-child) {
      margin-bottom: 30px;

      @include mobile {
        margin-bottom: 20px;
      }
    }
  }

  &__links {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;

    & > li {
      &:not(:last-child) {
        margin-bottom: 6px;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:not(:first-child) {
      margin-top: 30px;

      @include mobile {
        margin-top: 20px;
      }
    }
  }

  .my-modal__content {
    width: 100%;
    max-width: 657px;
  }
}
</style>
