<template>
  <div class="sign-up">
    <my-modal>
      <sign-up-step1 @complete="completeStep1" v-if="step === 1"/>
<!--      <sign-up-step2 @complete="completeStep2" v-if="step === 2" :redirect="redirect"/>-->
      <sign-up-step2 @complete="completeStep2" v-if="step === 2" />
      <sign-up-step3 @complete="completeStep3"
                     @back="returnToStart"
                     v-if="step === 3"
                     :phone="phone"
      />
      <sign-up-step4 @complete="completeStep4" v-if="step === 4"/>
      <sign-up-step5 :email="email" v-if="step === 5" @back="completeStep5" />
      <sign-up-step6 @complete="completeStep7" v-if="step === 6"/>
      <sign-up-step7 @complete="completeStep7" v-if="step === 7"/>
      <sign-up-step8 v-if="step === 8"/>
      <sign-up-step9 v-if="step === 9"/>
      <div v-if="step === 'deletion'">
        <div v-if="!deletionAgreed" class="form__description form__description_align-center">
          После удаления анкеты ваш аккаунт нельзя будет восстановить
        </div>
        <div v-else class="form__description form__description_align-center">
          Ваша анкета будет удалена {{deletionDate}}
        </div>
        <div v-if="!deletionAgreed">
          <div class="form__description form__description_align-center">
            <a href="#" class="form__link" @click.prevent="confirmDeletion">Удалить анкету</a>
          </div>
          <div class="form__description_align-center">
            <a href="#" class="form__link" @click.prevent="returnBack">Вернуться назад</a>
          </div>
        </div>
        <div v-else>
          <div class="form__description_align-center">
            <a href="#" class="form__link" @click.prevent="abortDeletion">Отменить удаление</a>
          </div>
        </div>
      </div>
    </my-modal>
  </div>
</template>

<script>
import MyModal from './MyModal';
import SignUpStep1 from './SignUpStep1';
import SignUpStep2 from './SignUpStep2';
import SignUpStep3 from './SignUpStep3';
import SignUpStep4 from './SignUpStep4';
import SignUpStep5 from './SignUpStep5';
import SignUpStep6 from './SignUpStep6';
import SignUpStep7 from './SignUpStep7';
import SignUpStep8 from './SignUpStep8';
import SignUpStep9 from './SignUpStep9';
import api from "../services/api";
import {parseQueryString} from "../utils";

export default {
  name: "SignUp",
  components: {
    MyModal,
    SignUpStep1,
    SignUpStep2,
    SignUpStep3,
    SignUpStep4,
    SignUpStep5,
    SignUpStep6,
    SignUpStep7,
    SignUpStep8,
    SignUpStep9,
  },
  props: {
    initStep: {type: Number, default: () => 1}
  },
  data() {
    return {
      step: this.initStep,
      email: '',
      memoStep: false,
      deletionAgreed: false,
      redirect: this.$attrs.redirect,
      phone: '',
    };
  },
  computed: {
    deletionDate() {
      let now = new Date();
      now.setDate(now.getDate() + 30);
      return now.toLocaleDateString();
    },
  },
  async created() {
    const params = parseQueryString(window.location.search.substring(1));

    if (typeof params['direct-crm-ticket'] !== 'undefined') {
      await this.emailTicketCheck(params);
    } else if (this.$store.state.registrationIncomplete && this.step !== 9 && this.step !== 8) {
      if (typeof this.$store.state.currentUser.segments.DocumentsModerationResend !== 'undefined') {
        this.redirect = this.$store.state.incompleteStep;
        this.step = 2;
      } else if (typeof this.$store.state.currentUser.segments.DocumentsModerationInProcess !== 'undefined') {
        this.step = this.$store.state.incompleteStep || 8;
      } else {
        this.step = this.$store.state.incompleteStep || 1;
      }
    }
  },
  async mounted() {
    this.$root.$on('signUpDeletionStep', this.goToStepDeletion);
  },
  destroyed() {
    this.$root.$off('signUpDeletionStep', this.goToStepDeletion);
  },
  methods: {
    async completeStep1(idxResult, phone) {
      this.phone = phone;
      if (idxResult.data) {
        this.step = 3; // Подтверждение телефона
      } else {
        this.step = 2; // Загрузка сканов документов
      }
    },
    async completeStep2(step) {
      if (step) {
        this.step = step;
        await api.simplePostRequest('/api/setRegistrationStep', {step: step === 8 ? false : step})
      } else {
        this.step = 3;
      }
    },
    completeStep3() {
      this.step = 4;
    },
    completeStep4(email) {
      this.email = email;
      this.step = 5;
    },
    completeStep5() {
      this.step = 4;
    },
    completeStep6() {
      this.step = 7;
    },
    completeStep7() {
      this.step = 8;
    },
    returnToStart() {
      this.step = 1;
      this.memoStep = false;
    },
    goToStepDeletion() {
      this.memoStep = this.step;
      this.step = 'deletion'
    },
    async confirmDeletion() {
      try {
        await api.simplePostRequest('/api/deletion');
        this.deletionAgreed = true;
      } catch (e) {
        this.$store.dispatch('setErrors', e);
      }
    },
    async abortDeletion() {
      await api.simplePostRequest('/api/deletionRecall');
      this.$store.commit('setDeletionStatus', null);
      this.deletionAgreed = false;
    },
    returnBack() {
      this.step = this.memoStep;
    },
    async emailTicketCheck(params) {
      const result = await api.simplePostRequest('/api/ticketAuthorizationFull', { ticket: params['direct-crm-ticket'] });
      /*
      if (result.needUpdatePersonalData) {
        return await this.$store.dispatch('goProfileForm');
      }
      */
      this.step = 6;
      await this.$store.dispatch('checkAuthentication');
      await this.$store.dispatch('segmentsJump', result?.stepRegistration);
    }
  },
}
</script>

<style lang="scss">
@import "../scss/base/_settings.scss";
.sign-up {
  min-height: 100%;

  .form__description {
    max-width: 456px;

    @include mobile {
      max-width: 100%;
    }
  }

  .my-modal__content {
    max-width: 657px;
  }

  .form__title {
    margin-bottom: 8px;
  }

  &-terms {
    &__title {
      font-size: 16px;
      text-align: center;
      font-weight: bold;
    }
    &__subtitle {
      font-size: 14px;
      font-weight: bold;
    }
    &__subheading {
      font-size: 12px;
      font-weight: bold;
      font-style: italic;
      line-height: 1.2;
    }
    &__text {
      font-size: 12px;
      line-height: 1.2;
      margin-top: 16px;
    }
    ul {
      padding-left: 21px;
    }
    li {
      font-size: 12px;
      line-height: 1.2;
      margin: 10px 0;
    }
    &__first-line {
      display: block;
      font-weight: bold;
    }
    &__footnotes {
      margin-top: 1em;
      padding-top: 8px;
      border-top: 1px solid $brand-color;
    }
    &__footnote {
      font-size: 10px;
      line-height: 1.2;
    }
  }
}
.mx-datepicker-popup {
  z-index: 9999;
}
</style>
