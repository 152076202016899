<template>
  <form class="form" @submit.prevent="submit">
    <h2 class="form__title">Восстановление пароля</h2>
    <div class="form__description form__description_align-center">
      <p>
        Для того, чтобы восстановить свой пароль, введите указанный
        при&nbsp;регистрации номер мобильного телефона или&nbsp;адрес
        электронной почты и&nbsp;нажмите «Восстановить».
      </p>
    </div>
    <div class="form-row form-row_no-flex">
      <text-input
        label="Электронная почта или телефон*"
        v-model="$v.form.login.$model"
        name="login"
        :error="$v.form.login.$error"
      >
        <template #errors>
          <div class="text-input__error" v-if="$v.form.login.$error">
            <div v-if="!$v.form.login.required">
              Заполните поле
            </div>
            <div v-if="!$v.form.login.phoneOrEmail">
              Неверный формат электронной почты или телефона
            </div>
          </div>
        </template>
      </text-input>
    </div>
    <captcha-input
      v-if="showCaptcha"
      ref="captchaInput"
      v-model="$v.form.captcha.$model"
      :error="$v.form.captcha.$error"
      :sid="captchaSid"
      @requestUpdate="updateCaptchaOnRequest"
    />
    <div class="form__error-alert" v-show="hasError">
      Пожалуйста, проверьте правильность заполнения формы
    </div>
    <div class="reset-password__footer">
      <button
        class="reset-password__submit button button__lg button__blue"
        type="submit"
      >
        Восстановить
      </button>
      <ul class="reset-password__links">
        <li>
          Вспомнили пароль?
          <router-link :to="{ name: 'login' }" class="form__link"
            >Войти</router-link
          >
        </li>
        <li>
          Нет аккаунта?
          <router-link :to="{ name: 'register' }" class="form__link"
            >Зарегистрироваться</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'feedback' }" class="form__link"
            >Написать нам</router-link
          >
        </li>
      </ul>
    </div>
  </form>
</template>

<script>
import { required, or, email, helpers } from "vuelidate/lib/validators";
import TextInput from "./TextInput";
import CaptchaInput from "./CaptchaInput";
import api from "../services/api";
import invisibleCaptcha from "../mixins/invisibleCaptcha";

const phoneRegex = helpers.regex(
  "val1",
  /^(\+)?(\(\d{2,3}\) ?\d|\d)(([ -]?\d)|( ?\(\d{2,3}\) ?)){5,12}\d$/
);

export default {
  name: "ResetPasswordStep1",
  mixins: [invisibleCaptcha],
  components: {
    TextInput,
    CaptchaInput,
  },
  data() {
    return {
      hasError: false,
      form: {
        login: "",
        captcha: {
          sid: "",
          code: "",
        },
      },
    };
  },
  validations() {
    const form = {
      login: {
        required,
        phoneOrEmail: or(email, phoneRegex),
      },
    }
    this.addCaptchaValidationIfNeeded(form);
    return { form };
  },
  methods: {
    async submit() {
      // implementing invisible captcha
      await this.updateCaptchaOnSubmit();

      this.$v.form.$touch();
      this.hasError = this.$v.form.$invalid;
      if (!this.$v.form.$pending && !this.$v.form.$invalid) {
        this.$store.dispatch("showLoader");

        try {
          const result = await api.changePassword(this.form);
          if (result.operation === 'SMSWithAuthorizationCode') {
            this.$emit("complete", 2, this.form.login);
            this.$store.dispatch("hideErrorsBag");
          } else if (result.operation === 'RestorePasswordLinkRequested') {
            this.$emit("complete", 'email');
            this.$store.dispatch("hideErrorsBag");
          }
        } catch (e) {
          this.$store.dispatch("setErrors", e);
          await this.updateCaptchaOnError();
        } finally {
          this.$store.dispatch("hideLoader");
        }
      }
    },
  },
};
</script>
