<template>
  <my-modal>
    <template v-if="!isEdit">
      <form v-if="!sendSuccess" class="form" @submit.prevent="submit">
        <profile-tabs />
        <div class="form-row form-row_2-cols form-row_mobile-no-flex">
          <div>
            <text-input
              v-if="$route.name !== 'profile-fields' || !profile.lastName"
              label="Фамилия"
              v-model="profile.lastName"
              name="lastName"
              disabled
            />
            <text-input
              v-if="$route.name !== 'profile-fields' || !profile.firstName"
              label="Имя"
              v-model="profile.firstName"
              name="firstName"
              disabled
            />
            <text-input
              v-if="$route.name !== 'profile-fields' || !profile.middleName"
              label="Отчество"
              v-model="profile.middleName"
              name="middleName"
              disabled
            />
          </div>
          <div>
            <text-input
              v-if="$route.name !== 'profile-fields' || !profile.birthDate"
              label="Дата рождения"
              :value="profile.birthDate | dateFilter"
              name="birthDate"
              append-classes="_date"
              disabled
            />
            <div v-if="$route.name !== 'profile-fields' || !profile.sex" class="form-group">
              Пол
              <radio-input
                label="Мужской"
                value="male"
                name="sex"
                v-model="profile.sex"
                disabled
              />
              <radio-input
                label="Женский"
                value="female"
                name="sex"
                v-model="profile.sex"
                disabled
              />
            </div>
          </div>
        </div>
        <h2 class="form__subtitle _align-left">Контактные данные</h2>
        <div class="form-row form-row_2-cols _alt form-row_mobile-no-flex">
          <div>
            <text-input
              label="Электронная почта"
              v-model="profile.email"
              name="email"
              disabled
            />
            <div v-if="!profileData.isEmailConfirmed" class="pending-label">Ожидает подтверждения</div>
          </div>

          <div>
            <button
              class="form__submit button button__md button__blue"
              type="button"
              @click="changeCredentials('email')"
            >Редактировать</button>
          </div>
        </div>

        <div class="form-row form-row_2-cols _alt form-row_mobile-no-flex">
          <div>
            <text-input
              label="Телефон"
              type="tel"
              :value="String(profile.mobilePhone).slice(1)"
              name="mobilePhone"
              disabled
            />
            <div v-if="!profileData.isMobilePhoneConfirmed" class="pending-label">Ожидает подтверждения</div>
          </div>
          <div>
            <button
              class="form__submit button button__md button__blue"
              type="button"
              @click="changeCredentials('phone')"
            >Редактировать</button>
          </div>
        </div>
        <div v-if="isLoaded && (
          !address.customerCity
          || !address.customerStreet
          || !address.customerIndex
          || !address.customerHouse
          || !address.customerFlat
        )"
             class="form-row form-row_no-flex">
          <h2 class="form__subtitle _align-left">Адрес</h2>
          <div class="form-row form-row_2-cols form-row_mobile-no-flex">
            <dadata-custom
              v-if="$route.name !== 'profile-fields' || !address.customerCity"
              label="Населенный пункт"
              v-model="address.customerCity"
              :error="$v.address.customerCity.$error"
              from-bound="city"
              to-bound="settlement"
              disabled
            >
              <template #errors>
                <div class="dadata-input__error" v-if="$v.address.customerCity.$error">
                  <div v-if="!$v.address.customerCity.required">Обязательное поле</div>
                </div>
              </template>
            </dadata-custom>
            <dadata-custom
              v-if="$route.name !== 'profile-fields' || !address.customerStreet"
              label="Улица"
              v-model="address.customerStreet"
              :error="$v.address.customerStreet.$error"
              from-bound="street"
              to-bound="street"
              :location="address.customerCity"
              :disabled="!address.customerCity"
            >
              <template #errors>
                <div class="dadata-input__error" v-if="$v.address.customerStreet.$error">
                  <div v-if="!$v.address.customerStreet.required">Обязательное поле</div>
                </div>
              </template>
            </dadata-custom>
          </div>
          <div class="form-row form-row_2-cols form-row_mobile-no-flex">
            <dadata-custom
              v-if="$route.name !== 'profile-fields' || !address.customerHouse"
              label="Дом/Строение"
              v-model="address.customerHouse"
              :error="$v.address.customerHouse.$error"
              :constraints.sync="address.customerStreet"
              from-bound="house"
              to-bound="house"
              :location="`${address.customerCity}, ${address.customerStreet}`"
              :disabled="!address.customerCity"
            >
              <template #errors>
                <div class="dadata-input__error" v-if="$v.address.customerHouse.$error">
                  <div v-if="!$v.address.customerHouse.required">Обязательное поле</div>
                </div>
              </template>
            </dadata-custom>
            <text-input
              v-if="$route.name !== 'profile-fields' || !address.customerFlat"
              label="Квартира"
              v-model="address.customerFlat"
              name="customerFlat"
              tabindex="5"
              disabled
              class="disabled"
            ></text-input>
          </div>
          <div class="form-row form-row_2-cols form-row_mobile-no-flex">
            <text-input
              v-if="$route.name !== 'profile-fields' || !address.customerIndex"
              label="Индекс"
              v-model="address.customerIndex"
              name="customerIndex"
              tabindex="4"
              disabled
              class="disabled"
            ></text-input>
          </div>
          <div v-if="!profileData.personalDataAgreementSignature && $route.name === 'profile-fields'"
               class="form-row form-row_2-cols form-row_mobile-no-flex">
            <sign-input v-model="profile.personalDataAgreementSignature"/>
          </div>
        </div>
        <h2 class="form__subtitle _align-left">ОПРОСНИК ПО ТАБАЧНЫМ ПРЕДПОЧТЕНИЯМ</h2>
        <div class="form-row form-row_2-cols form-row_mobile-no-flex _g-items-flex-end">
          <div>
             <div class="form-row__description">Как давно Вы&nbsp;курите?</div>
             <text-input
              label="Период"
              :value="primaryBrandAgeField"
              name="primaryBrandAgeField"
              tabindex="5"
              disabled
            ></text-input>
          </div>
          <div>
             <div class="form-row__description">Сколько сигарет Вы выкуриваете в&nbsp;день?</div>
             <text-input
              label="Количество"
              :value="profileData.customFields.cigaretteCountPerDayField"
              name="cigaretteCountPerDayField"
              tabindex="5"
              disabled
            ></text-input>
          </div>
        </div>
        <div v-if="getCigaretteSubfamilyItemName(profileData.customFields.primaryCigaretteSubfamily)"
             class="form-row form-row_2-cols form-row_mobile-no-flex _g-items-flex-end"
        >
          <div>
            <div class="form-row__description">Какую марку сигарет Вы покупали и&nbsp;курили чаще всего в&nbsp;течение последних 4-х недель?</div>
            <text-input
                label="Марка сигарет"
                :value="getCigaretteSubfamilyItemName(profileData.customFields.primaryCigaretteSubfamily)"
                name="primaryCigaretteSubfamily"
                tabindex="5"
                disabled
            ></text-input>
          </div>
          <div>
            <div class="form-row__description">Из последних десяти пачек, сколько составили пачки этой марки?</div>
            <text-input
                label="Количество"
                :value="profileData.customFields.primaryBrandShareField"
                name="primaryBrandShareField"
                tabindex="5"
                disabled
            ></text-input>
          </div>
        </div>
        <div v-if="getCigaretteSubfamilyItemName(profileData.customFields.secondaryCigaretteSubfamily)"
             class="form-row form-row_2-cols form-row_mobile-no-flex _g-items-flex-end"
        >
          <div>
            <div class="form-row__description">Какую марку сигарет Вы&nbsp;купите, если Вашей основной марки нет в&nbsp;продаже?</div>
            <text-input
                label="Марка сигарет"
                :value="getCigaretteSubfamilyItemName(profileData.customFields.secondaryCigaretteSubfamily)"
                name="primaryCigaretteSubfamily"
                tabindex="5"
                disabled
            ></text-input>
          </div>
        </div>
        <div class="form-row form-row_align-center-on-desktop form-row_wrap ">
          <div class="form-row__description">
            <router-link
                :to="{ path: (this.$route.name === 'profile-fields' ? '/register' : '') + '/consumption-survey' }"
                class="form__link">Внести</router-link> изменения в опросник по табачным предпочтениям</div>
        </div>
        <!--div class="form-row form-row_align-center form-row_mobile-wrap">
          <button class="form__submit button button__lg button__blue" type="submit">Сохранить</button>
        </div-->

        <div class="form-row form-row_footnote">
          <div class="form-row__description">
            Если необходимо скорректировать личные данные, адрес или документы, <a href="/feedback" class="form__link">свяжитесь с нами</a>
          </div>
        </div>

        <div class="form-row form-row_links ">
          <div class="form-row__description">
            <router-link
              :to="{ name: 'profile-password' }"
              class="form__link"
            >
              Сменить пароль
            </router-link>
          </div>
          <div class="form-row__description">
            <router-link
              :to="{ name: 'profile-delete' }"
              class="form__link"
            >
              Управлять анкетой
            </router-link>
          </div>
        </div>

      </form>
      <div v-else>
        <div class="form-row form-row_align-center">
          <p>Обновлённая информация сохранена</p>
        </div>
        <div class="form-row form-row_align-center">
          <button type="button" class="button button__blue button__lg" @click.prevent="returnToProfile2">
            Назад
          </button>
        </div>
      </div>
    </template>
    <profile-credentials-change v-else :changeType="changeType" :profile="profileData" @returnToProfile="returnToProfile"/>
  </my-modal>
</template>

<script>
import MyModal from "../MyModal";
import TextInput from "../TextInput";
import {required, email} from "vuelidate/lib/validators";
import api from "../../services/api";
import ProfileCredentialsChange from "./ProfileCredentialsChange";
import { debounce } from "lodash";
import SignInput from '../SignInput';
//import invisibleCaptcha from "../../mixins/invisibleCaptcha";

export default {
  name: "ProfileForm",
  //mixins: [invisibleCaptcha],
  components: {ProfileCredentialsChange, TextInput, MyModal, SignInput},
  data() {
    return {
      profile: {
        lastName: '',
        firstName: '',
        middleName: '',
        birthDate: '',
        sex: '',
        email: '',
        mobilePhone: '',
        personalDataAgreementSignature: '',
      },
      address: {
        fiasId: '',
        customerCity: '',
        customerStreet: '',
        customerIndex: '',
        customerHouse: '',
        customerFlat: '',
      },
      isLoaded: false,
      isEdit: false,
      changeType: null,
      sendSuccess: false,
      primaryBrandAgeField: '',
      smokePeriodOptions: {
        'lessThan3Months': 'До 3 месяцев',
        '3to6Months':'3-6 месяцев',
        '6to12Months': '6-12 месяцев',
        'moreThanAYear': 'Больше года'
      }
    }
  },
  validations: {
    profile: {
      email: {required, email},
      mobilePhone: {required},
    },
    address: {
      fiasId: {required},
      customerCity: {required},
      customerStreet: {required},
      customerIndex: {required},
      customerHouse: {required},
    },
  },
  watch: {
    fullAddress(val) {
      this.getAddressInfo(val);
    }
  },
  computed: {
    dadataToken() {
      return '44f7453167d491b6b74dec9c95bc71f66de244cf';
    },
    profileData() {
      return this.$route.params && this.$route.params.email
        ? { ...this.$store.state.profileData, email: this.$route.params.email }
        : this.$store.state.profileData;
    },
    fullAddress() {
      return `${this.address.customerCity}, ${this.address.customerStreet}, ${this.address.customerHouse}`
    },
    cigaretteMarks() {
      return this.$store.state.cigaretteMarks || [];
    },
  },
  async mounted() {
    await this.updateProfileData();
    this.isLoaded = true;

    if (this.$route.params && this.$route.params.email) {
      this.isEdit = true
      this.changeType = 'email'
    }
  },
  methods: {
    getAddressInfo: debounce(async function(val) {
      try {
          const { data = {} } = await api.DADATA.getAddress({
            data: {
              query: val,
              count: 1,
              restrict_value: true,
            },
          });
            this.address.fiasId = data.suggestions.length? data.suggestions[0].data.fias_id: '';
            this.address.customerIndex = data.suggestions.length? data.suggestions[0].data.postal_code: '';
        } catch (e) {
          console.log(e);
        }
    }, 1000),
    async submit() {
      // implementing invisible captcha
      // await this.updateCaptchaOnSubmit();

      this.$v.$touch();
      if ((!this.$v.$pending && !this.$v.$invalid)) {
        try {
          this.$store.dispatch("showLoader");
          const result = await api.simplePostSuccess('/api/updateMindboxUserFields', {
            ...this.profile,
            ...this.address
          });
          if (result) {
            if (this.$route.name === 'profile-fields') {
              await this.$store.dispatch('checkAuthentication');
              await this.$store.dispatch('segmentsJump');
            } else {
              this.$v.$reset();
              await this.$store.dispatch('fetchProfile');
              await this.updateProfileData();
            }
            this.sendSuccess = true;
          }
        } catch (e) {
          this.$store.dispatch('setErrors', e);
        } finally {
          this.$store.dispatch("hideLoader");
        }
      }
    },
    changeCredentials(type) {
      this.isEdit = true;
      this.changeType = type;
    },
    async returnToProfile() {
      await this.$store.dispatch('fetchProfile');
      await this.updateProfileData();
      this.isEdit = false;
      this.changeType = null;
    },
    async returnToProfile2() {
      this.sendSuccess = false;
      this.isLoaded = false;
      await this.updateProfileData();
      this.isLoaded = true;
    },
    setAddress(suggest) {
      this.address.fiasId = suggest.fias_id;
      this.address.customerCity = suggest.city_with_type ?? '';
      this.address.customerStreet = suggest.street_with_type ?? '';
      this.address.customerHouse = suggest.house ? `${suggest.house_type} ${suggest.house}` : '';
      this.address.customerFlat = suggest.flat ? `${suggest.flat_type} ${suggest.flat}` : '';
      this.address.customerIndex = suggest.postal_code || '';
    },
    getCigaretteSubfamilyItemName(cigaretteSubFamily) {
      let name = ''

      if (cigaretteSubFamily) {
        this.cigaretteMarks.some(item => {
          if (typeof item.subfamilies !== 'undefined') {
            name = item.subfamilies.find(subItem => subItem.spicId === cigaretteSubFamily)?.name || ''
            return !!name
          }
          return false;
        });
      }

      return name
    },
    async updateProfileData() {
      Object.keys(this.profile).forEach(key => this.profile[key] = this.profileData[key] || '');
      Object.keys(this.address).forEach(key => this.address[key] = this.profileData.customFields[key] || '');
      this.profile.personalDataAgreementSignature = this.profileData.customFields.personalDataAgreementSignature || '';

      if (this.profileData.customFields.primaryCigaretteSubfamily || this.profileData.customFields.secondaryCigaretteSubfamily) {
        await this.$store.dispatch('fetchCigaretteMarks');
      }

      this.primaryBrandAgeField = this.profileData.customFields.primaryBrandAgeField ? this.smokePeriodOptions[this.profileData.customFields.primaryBrandAgeField] : '';
      if (!this.profileData.isEmailConfirmed && this.profileData.pendingEmail) {
        this.profile.email = this.profileData.pendingEmail;
      }
      if (!this.profileData.isMobilePhoneConfirmed && this.profileData.pendingMobilePhone) {
        this.profile.mobilePhone = this.profileData.pendingMobilePhone;
      }
      if (this.profileData.ids.externalId) {
        this.address.customerCity = await api.findAddress(this.dadataToken, this.profileData.ids.externalId, 'city_with_type');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../scss/base/_settings.scss";
  .form-row {
    > div {
      &:not(:last-child) {
        @include mobile {
          margin-bottom: 16px;
        }
      }
    }
    // overriding previous block, needs refactoring
    &_links div.form-row__description {
      margin: 0;
    }
    &_footnote {
      margin-top: -16px;
    }
    &_2-cols {
      &._alt {
        > * {
          @include mobile-min {
            &:first-child {
              width: calc(60% - 10px);
            }
            &:last-child {
              width: calc(40% - 10px);
            }
          }
        }
      }
    }
  }
  .text-input {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
  .pending-label {
    margin-top: -15px;
  }
</style>
