<template>
  <form class="form" @submit.prevent="submit">
    <h2 class="form__title">Восстановление пароля</h2>
    <div class="form__description form__description_align-center form-row__description">
      Пожалуйста, придумайте пароль, принимая во&nbsp;внимание следующие
      <a href="#" @click="passwordRequirementsShow = !passwordRequirementsShow">требования к&nbsp;паролю</a>
    </div>
    <ul v-if="passwordRequirementsShow" class="form__description">
      <li>минимум 8 символов</li>
      <li>минимум одна буква верхнего регистра</li>
      <li>минимум одна буква нижнего регистра</li>
      <li>минимум одна цифра</li>
      <li>минимум один спец. символ (!@#$%^&*)</li>
    </ul>
    <div class="form-row form-row_no-flex">
      <text-input
        label="Новый пароль*"
        name="password"
        type="password"
        v-model="$v.form.password.$model"
        :error="$v.form.password.$error"
      >
        <template #errors>
          <div class="text-input__error" v-if="$v.form.password.$error">
            <div v-if="!$v.form.password.required">Заполните поле</div>
          </div>
        </template>
      </text-input>
    </div>
    <div class="form-row form-row_no-flex">
      <text-input
        label="Повторите пароль*"
        name="confirmPassword"
        type="password"
        v-model="$v.form.confirmPassword.$model"
        :error="$v.form.confirmPassword.$error"
      >
        <template #errors>
          <div class="text-input__error" v-if="$v.form.confirmPassword.$error">
            <div v-if="!$v.form.confirmPassword.required">Заполните поле</div>
            <div v-if="$v.form.confirmPassword.required&&!$v.form.confirmPassword.sameAs">
              Пароли не совпадают
            </div>
          </div>
        </template>
      </text-input>
    </div>
    <div class="form__error-alert" v-show="hasError">
      Пожалуйста, проверьте правильность заполнения формы
    </div>
    <div class="reset-password__footer">
      <button
        class="reset-password__submit button button__lg button__blue"
        type="submit"
      >
        Восстановить
      </button>
    </div>
  </form>
</template>

<script>
import { required, sameAs } from "vuelidate/lib/validators";
import TextInput from "./TextInput";
import api from "../services/api";
//import invisibleCaptcha from "../mixins/invisibleCaptcha";

export default {
  name: "ResetPasswordStep3",
  //mixins: [invisibleCaptcha],
  components: {
    TextInput,
  },
  data() {
    return {
      hasError: false,
      form: {
        password: "",
        confirmPassword: "",
      },
      passwordRequirementsShow: false,
    };
  },
  validations: {
    form: {
      password: { required },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },
  methods: {
    async submit() {
      // implementing invisible captcha
      // await this.updateCaptchaOnSubmit();

      this.$v.form.$touch();
      this.hasError = this.$v.form.$invalid;

      if (!this.$v.form.$pending && !this.$v.form.$invalid) {
        this.$store.dispatch("showLoader");

        try {
          const result = await api.simplePostRequest('/api/updateMindboxUserPassword', this.form);
          if (result) {
            this.$emit("complete", "final");
            this.$store.dispatch("hideErrorsBag");
          }
        } catch (e) {
          this.$store.dispatch("setErrors", e);
        } finally {
          this.$store.dispatch("hideLoader");
        }
      }
    },
  },
};
</script>
