<template>
  <div class="profile-tabs">
    <router-link
        v-for="link in profileLinks"
        :key="link.to"
        :to="{ name: link.to, path: link.path }"
        class="profile-tabs__item"
      >{{link.name}}</router-link>
  </div>
</template>

<script>
export default {
  computed: {
    profileLinks() {
      const profileLinks = [
        //{path: '/products/compact-crush', name: 'Продукты'},
        {to: 'profile', name: 'Личные данные'},
        //{to: 'activity', name: 'Прогресс'},
        //{to: 'profile-documents', name: 'Документы'},
        //{to: 'profile-password', name: 'Смена пароля'},
        //{to: 'profile-delete', name: 'Действия с анкетой'},
        {to: 'profile-subscribe', name: 'Подписки'},
      ];

      return profileLinks;
    },
  }
}
</script>

<style lang="scss">
  @import "../../scss/base/_settings.scss";

  .profile-tabs {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    column-gap: 32px;
    margin-bottom: 32px;
    text-align: left;
    border-bottom: 1px solid $input-border;
    text-transform: uppercase;
    font-size: 26px;
    line-height: 1.3;
    @include mobile {
      font-size: 18px;
      margin-bottom: 20px;
    }

    &__item {
      position: relative;
      padding-bottom: 5px;
      color: $text-light;
      text-decoration: none;

      &.router-link-exact-active {
        color: $brand-color;
        &::after {
          content: "";
          display: block;
          position: absolute;
          bottom: -2px;
          width: 100%;
          height: 2px;
          border-radius: 1px;
          background: $brand-color;
        }
      }
    }
  }
</style>