<template>
  <my-modal>
    <form v-if="!sendSuccess" class="form" @submit.prevent="submit">
      <profile-tabs />
      <h2 class="form__subtitle">Глобальная подписка</h2>
      <div class="form__description">
        Если хотите подписаться или отписаться от всей коммуникации JTI
      </div>
      <div class="form__description">
        <b>Подписка на всю коммуникацию от JTI по электронной почте и SMS</b>
      </div>
      <div class="form-row form-row_radio">
        <radio-input
          label="да"
          :value="true"
          name="total"
          v-model="form.total"
          @change="changeTotal"
        />
        <radio-input
          label="нет"
          :value="false"
          name="total"
          v-model="form.total"
          @change="changeTotal"
        />
      </div>
      <h2 class="form__subtitle">Настроить по брендам и каналам</h2>
      <div class="form__description">
        Если хотите детально настроить подписки по удобному для Вас каналу
      </div>
      <div class="form__description">
        <b>Электронная почта</b>
      </div>
      <div class="form-row form-row_radio">
        <check-input
          label="MEVIUS"
          name="mainBrandEmail"
          v-model="form.checkboxes.mainBrandEmail"
        ></check-input>
        <check-input
          label="Остальные бренды"
          name="otherBrandEmail"
          v-model="form.checkboxes.otherBrandEmail"
        ></check-input>
      </div>
      <div class="form__description">
        <b>SMS</b>
      </div>
      <div class="form-row form-row_radio">
        <check-input
          label="MEVIUS"
          name="mainBrandSms"
          v-model="form.checkboxes.mainBrandSms"
        ></check-input>
        <check-input
          label="Остальные бренды"
          name="otherBrandSms"
          v-model="form.checkboxes.otherBrandSms"
        ></check-input>
      </div>
      <div class="form-row form-row_align-center form-row_mobile-wrap">
        <button class="form__submit button button__lg button__blue" type="submit">Сохранить</button>
      </div>
      <div class="form-row form-row_align-center">
        <router-link :to="{ path: '/cabinet' }" class="form__link">Назад</router-link>
      </div>
    </form>
    <div v-else>
      <div class="form-row form-row_align-center">
        <p>
          Обновлённая информация сохранена
        </p>
      </div>
      <div class="form-row form-row_align-center">
        <router-link :to="{ path: '/cabinet' }" class="button button__blue button__lg">Назад</router-link>
      </div>
    </div>
  </my-modal>
</template>

<script>
  import CheckInput from "../CheckInput";
  import api from "../../services/api";
  //import invisibleCaptcha from "../../mixins/invisibleCaptcha";

  export default {
    name: "ProfileSubscribe",
    //mixins: [invisibleCaptcha],
    components: {CheckInput},
    data() {
      return {
        form: {
          total: false,
          checkboxes: {
            mainBrandEmail: false,
            mainBrandSms: false,
            otherBrandEmail: false,
            otherBrandSms: false,
          },
        },
        sendSuccess: false
      }
    },
    watch: {
      'form.checkboxes': {
        handler() {
          this.form.total = Object.keys(this.form.checkboxes).every(key => this.form.checkboxes[key]);
        },
        deep: true,
      }
    },
    mounted() {
      const subscriptions = this.$store.state.profileData.subscriptions;
      subscriptions.forEach((item) => {
        if (item.brand === 'MildSeven') {
          if (item.pointOfContact === 'Sms') {
            item.isSubscribed && (this.form.checkboxes.mainBrandSms = true);
          } else if (item.pointOfContact === 'Email') {
            item.isSubscribed && (this.form.checkboxes.mainBrandEmail = true);
          }
        } else {
          if (item.pointOfContact === 'Sms') {
            item.isSubscribed && (this.form.checkboxes.otherBrandSms = true);
          } else if (item.pointOfContact === 'Email') {
            item.isSubscribed && (this.form.checkboxes.otherBrandEmail = true);
          }
        }
      });

      this.$nextTick(() =>{
        this.form.total = subscriptions.every(item => item.isSubscribed);
      })
    },
    methods: {
      async submit() {
      // implementing invisible captcha
      // await this.updateCaptchaOnSubmit();

        this.$store.dispatch("showLoader");
        try {
          const result = await api.simplePostRequest('/api/updateMindboxUserSubscriptions', {
            total: this.form.total,
            sms1: this.form.checkboxes.mainBrandSms,
            sms2: this.form.checkboxes.otherBrandSms,
            email1: this.form.checkboxes.mainBrandEmail,
            email2: this.form.checkboxes.otherBrandEmail,
          });
          if (result) {
            await this.$store.dispatch('fetchProfile');
            this.sendSuccess = true;
            this.$store.dispatch('hideErrorsBag');
          }
        } catch (e) {
          this.$store.dispatch('setErrors', e);
        } finally {
          this.$store.dispatch("hideLoader");
        }
      },
      changeTotal() {
        Object.keys(this.form.checkboxes).forEach((key) => { this.form.checkboxes[key] = this.form.total });
      }
    }
  }
</script>
