<template>
  <div class="form-captcha">
    <div class="form-captcha__image">
      <button
        title="Обновить изображение"
        class="form-captcha__refresh"
        type="button"
        @click.prevent="handleRefreshButtonClick"
      >
        Обновить изображение
      </button>
      <img :src="`/bitrix/tools/captcha.php?captcha_sid=${value.sid}`" alt="" v-if="value.sid">
    </div>
    <text-input
      label="Код с картинки"
      v-model="value.code"
      name="captcha"
      autocomplete="off"
      :tabindex="tabindex"
      :error="error"
      :key="key"
    >
      <template #errors>
        <slot name="errors"/>
      </template>
    </text-input>
  </div>
</template>

<script>
import TextInput from "./TextInput";

export default {
  name: "CaptchaInput",
  components: {
    TextInput,
  },
  props: {
    tabindex: {type: [String, Number]},
    value: {type: Object},
    error: {type: Boolean, default: null},
    sid: {type: String, default: ''}
  },
  data() {
    return {
      key: 0,
    }
  },
  created() {
    this.setValue(this.sid);
  },
  methods: {
    setValue(sid) {
      this.value.sid = sid;
      this.value.code = '';
      this.key += 1;
    },
    handleRefreshButtonClick() {
      this.$emit('requestUpdate');
    }
  },
  watch: {
    sid(newValue) {
      if (newValue) {
        this.setValue(newValue);
      }
    }
  }
}
</script>
<style lang="scss">
@import "../scss/base/_settings.scss";
.form-captcha {
  @include mobile {
    gap: 16px;
  }
}
</style>
