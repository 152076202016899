import {required} from "vuelidate/lib/validators";
import api from "../services/api";
export default {
  data() {
    return {
      showCaptcha: false,
      captchaSid: ''
    }
  },
  methods: {
    async updateCaptchaOnCreate() {
      this.updateCaptcha();
    },
    async updateCaptchaOnSubmit() {
      //this.updateCaptchaIfHidden();
    },
    async updateCaptchaOnError() {
      await this.updateCaptcha();
    },
    async updateCaptchaOnRequest() {
      await this.updateCaptcha();
    },
    async updateCaptchaIfHidden() {
      if (!this.showCaptcha) {
        await this.updateCaptcha();
      }
    },
    async updateCaptcha() {
      const sid = await api.fetchCaptchaCode();
      this.captchaSid = sid;
      this.showCaptcha = !!sid;

      // если капча скрыта, обнулить данные в объекте формы
      if (!this.showCaptcha) {
        this.form.captcha.sid = '';
        this.form.captcha.code = '';
      }
    },
    addCaptchaValidationIfNeeded(form) {
      if (this.showCaptcha) {
        form.captcha = {
          sid: {required},
          code: {required},
        }
      }
    }
  },
  async created() {
    await this.updateCaptchaOnCreate();
  }
}
