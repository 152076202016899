<template>
  <div class="fileinput fileinput-multiple" ref="fileDrop"
      :class="{empty: !value}"
       @dragenter.prevent="highlight"
       @dragover.prevent="highlight"
       @dragleave.prevent="unhighlight"
       @drop.prevent="handleDrop($event)"
  >
    <h3 class="fileinput__title fileinput-multiple__title">
      <slot name="title"/>
    </h3>
    <!-- a href="" v-if="value != ''" class="fileinput__delete" @click.prevent="clearInput" title="Удалить"></a-->
    <label :class="{ 'fileinput-multiple__label_disabled': maxAmountReached }">
      <input type="file" :name="inputName" @change.prevent="getInputValue" ref="fileInput">
      <span class="fileinput__button fileinput-multiple__button">Выбрать файл</span>
      <!--span class="fileinput__label">
        <template v-if="value === ''">Файл не выбран</template>
        <template v-else>{{ value }}</template>
      </span-->
    </label>
    <slot name="errors" v-bind="{ minSizeError, maxSizeError, extensionError, maxAmountError }" />
    <div class="fileinput__label" v-if="files.length === 0">Файлы не выбраны</div>
    <div v-else class="fileinput-multiple__selected-files">
      <div v-for="file, index in files" :key="`${index}_${file.name}`" class="fileinput-multiple__selected-file">
        <div class="fileinput-multiple__filename">
          {{ file.name }}
        </div>
        <div @click.prevent="handleDeleteButtonClick(index)" class="fileinput-multiple__button-delete"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MultipleFilesInput',
  props: {
    inputTitle: {
      type: String,
      default: '',
    },
    inputName: {
      type: String,
      default: '',
    },
    maxSize: {
      type: Number,
      default: 10 * 1024 * 1024
    },
    minSize: {
      type: Number,
      default: 0
    },
    maxAmount: {
      type: Number,
      default: 3
    },
    allowedExtensions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      value: '',
      file: null,
      files: []
    }
  },
  methods: {
    handleDeleteButtonClick(index) {
      this.files.splice(index, 1);
      this.file = null;
      this.$refs.fileInput.value = '';
      this.$emit('change', this.files);
    },
    getInputValue() {
      this.file = this.$refs.fileInput.files[0];
      if (!this.file) return;

      if (!this.maxAmountError && !this.minSizeError && !this.maxSizeError && !this.extensionError) {
        this.files.push(this.file);
        this.value = this.$refs.fileInput.value.replace(/C:\\fakepath\\/i, '');
        this.$emit('change', this.files);
      } else {
        this.clearInput();
        return false;
      }
    },
    clearInput() {
      this.value = '';
      this.$refs.fileInput.value = '';
    },
    handleDrop(e) {
      this.unhighlight();
      let dt = e.dataTransfer;
      this.file = dt.files[0];

      if (!this.sizeError && !this.extensionError) {
        this.value = this.file.name;
        this.$emit('change', this.file);
      } else {
        this.clearInput();
        return false;
      }
    },
    highlight() {
      this.$refs.fileDrop.classList.add('_highlight');
    },
    unhighlight() {
      this.$refs.fileDrop.classList.remove('_highlight');
    }
  },
  computed: {
    minSizeError() {
      if (!this.file) return false;
      return this.file.size < this.minSize;
    },
    maxSizeError() {
      if (!this.file) return false;
      return this.file.size > this.maxSize;
    },
    extensionError() {
      if (!this.file) return false;
      if (!this.allowedExtensions.length) return false;
      const list = this.file.name.split('.');
      const extension = list[list.length - 1];
      if (!extension) return true;
      return !this.allowedExtensions.some(element => element === extension);
    },
    maxAmountError() {
      return (this.files.length === this.maxAmount) && (this.files[this.files.length - 1] !== this.file);
    },
    maxAmountReached() {
      return this.files.length >= this.maxAmount;
    }
  },
}
</script>
