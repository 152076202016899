<template>
  <div>
    <form @submit.prevent="submit" v-if="!verifyPersonError">
      <h2 class="form__title">Регистрация</h2>
      <div class="form__description form__description_align-center">
        <p>
          Чтобы зарегистрироваться на нашем сайте, пожалуйста, внимательно<br>
          заполните анкету. Обязательные поля отмечены звездочкой (*)
        </p>
      </div>
      <h3 class="form__subtitle form__subtitle_big-margin">1 шаг. Персональная информация</h3>
      <div class="form-row form-row_2-cols form-row_mobile-no-flex">
        <text-input
          label="Фамилия*"
          class="text-input__cyrillic"
          name="last-name"
          tabindex="1"
          v-on:keypress.prevent="blockSpecialChar"
          v-model="$v.form.lastName.$model"
          :error="$v.form.lastName.$error"
        >
          <template #errors>
            <div class="text-input__error" v-if="$v.form.lastName.$error">
              <div v-if="!$v.form.lastName.required">Заполните поле</div>
            </div>
          </template>
        </text-input>
        <text-input
          label="Имя*"
          name="first-name"
          class="text-input__cyrillic _g-visible-mobile"
          tabindex="2"
          v-model="$v.form.firstName.$model"
          :error="$v.form.firstName.$error"
        >
          <template #errors>
            <div class="text-input__error" v-if="$v.form.firstName.$error">
              <div v-if="!$v.form.firstName.required">Заполните поле</div>
            </div>
          </template>
        </text-input>
        <text-input
          label="Отчество*"
          name="middleName"
          class="text-input__cyrillic _g-visible-mobile"
          tabindex="3"
          v-model="$v.form.middleName.$model"
          :error="$v.form.middleName.$error"
        >
          <template #errors>
            <div class="text-input__error" v-if="$v.form.middleName.$error">
              <div v-if="!$v.form.middleName.required">Заполните поле</div>
            </div>
          </template>
        </text-input>
        <div class="text-input text-input_date" :class="{
        'text-input_filled': form.birthDate.length,
        'text-input_focused': dateFocus,
        'text-input_invalid': $v.form.birthDate.$error
        }">
          <label class="text-input__label"> Дата рождения* </label>
          <date-picker
            v-model="form.birthDate"
            format="DD-MM-YYYY"
            valueType="DD-MM-YYYY"
            type="date"
            placeholder="дд.мм.гггг"
            :clearable="false"
            :default-value="adultBirthDateInit"
            :disabled-date="adultBirthDate"
            @open="dateFocus = true"
            @close="dateFocus = false"
          >
            <template #input>
              <input v-model="form.birthDate" type="text" class="text-input__field _date" v-mask="'##-##-####'">
            </template>
          </date-picker>
          <div class="text-input__error" v-if="$v.form.birthDate.$error">
            <div v-if="!$v.form.birthDate.required">Заполните поле</div>
            <div v-if="$v.form.birthDate.required && !$v.form.birthDate.availableDate">Некорректная дата</div>
          </div>
        </div>
      </div>
      <div class="form-row form-row_2-cols form-row_mobile-no-flex">
        <text-input
          label="Имя*"
          name="first-name"
          class="text-input__cyrillic _g-hidden-mobile"
          tabindex="2"
          v-model="$v.form.firstName.$model"
          :error="$v.form.firstName.$error"
        >
          <template #errors>
            <div class="text-input__error" v-if="$v.form.firstName.$error">
              <div v-if="!$v.form.firstName.required">Заполните поле</div>
            </div>
          </template>
        </text-input>
        <text-input
          label="Телефон*"
          type="tel"
          name="phone"
          tabindex="5"
          v-model="$v.form.mobilePhone.$model"
          :error="$v.form.mobilePhone.$error"
        >
          <template #errors>
            <div class="text-input__error" v-if="$v.form.mobilePhone.$error">
              <div v-if="!$v.form.mobilePhone.required">Заполните поле</div>
            </div>
          </template>
        </text-input>
      </div>
      <div class="form-row form-row_2-cols form-row_mobile-no-flex">
        <text-input
          label="Отчество*"
          name="middleName"
          class="text-input__cyrillic _g-hidden-mobile"
          tabindex="3"
          v-model="$v.form.middleName.$model"
          :error="$v.form.middleName.$error"
        >
          <template #errors>
            <div class="text-input__error" v-if="$v.form.middleName.$error">
              <div v-if="!$v.form.middleName.required">Заполните поле</div>
            </div>
          </template>
        </text-input>
        <div class="form-group form-block_wrapping">
          Пол*
          <radio-input
            label="Мужской"
            value="male"
            name="sex"
            tabindex="6"
            v-model="$v.form.sex.$model"
          />
          <radio-input
            label="Женский"
            value="female"
            name="sex"
            tabindex="7"
            v-model="$v.form.sex.$model"
          />
          <div v-if="$v.form.sex.$error" class="radio-input__error">Заполните поле</div>
        </div>
      </div>
      <div class="form-row form-block_wrapping form-row_mobile-no-flex">
        <check-input-personal-data-consent
          v-model="$v.form.personalDataConsent.$model"
          @change="$v.form.personalDataConsent.$touch()"
          :error="$v.form.personalDataConsent.$error"
          name="personalDataConsent"
        />
      </div>
      <div class="form-row form-row_mobile-no-flex">
        <check-input
          v-model="$v.form.newsletterConsent.$model"
          name="newsletterConsent"
        >
          Я даю согласие на&nbsp;получение информационной рассылки от&nbsp;ООО&nbsp;«Дж.Т.И.&nbsp;Россия», включая передачу коротких текстовых сообщений, в&nbsp;том числе всплывающих сообщений
        </check-input>
      </div>
      <!--sign-input v-model="form.personalDataAgreementSignature"/-->
      <div class="form-row">
        <div class="form__note">
          <p>Внимательно проверьте введенные данные.<br/> После регистрации Вы не сможете их изменить.</p>
        </div>
      </div>
      <captcha-input
        v-if="showCaptcha"
        ref="captchaInput"
        tabindex="8"
        v-model="$v.form.captcha.$model"
        :error="$v.form.captcha.$error"
        :sid="captchaSid"
      @requestUpdate="updateCaptchaOnRequest"
      >
        <template #errors>
          <div class="text-input__error" v-if="$v.form.captcha.$error">
            <div v-if="!$v.form.captcha.required">Заполните поле</div>
          </div>
        </template>
      </captcha-input>
      <div class="form-row form-row_mobile-wrap form__actions">
        <div class="form__nav">
          <router-link v-if="!$store.state.currentUser" :to="{name: 'login'}" class="form__link">Войти</router-link>
          <router-link :to="{ name: 'feedback' }" class="form__link">Написать нам</router-link>
        </div>
        <button class="form__submit button button__lg button__blue" type="submit">
          Далее
        </button>
      </div>
    </form>
    <div class="sign-up-error" v-else>
      <h2 class="form__title">Системная ошибка</h2>
      <p class="form__description form__description_align-center">Пожалуйста, повторите попытку позже, или обратитесь <br/>в&nbsp;службу поддержки.</p>
      <button class="form__submit button button__lg button__blue" @click="submit" type="submit">Далее</button>
    </div>
  </div>
</template>

<script>
import {required, helpers} from "vuelidate/lib/validators";
import CheckInput from "./CheckInput";
import RadioInput from './RadioInput';
import TextInput from './TextInput';
//import SignInput from './SignInput';
import CaptchaInput from "./CaptchaInput";
import api from '../services/api';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';
import 'vue2-datepicker/index.css';
import {VueMaskDirective} from "v-mask";
import invisibleCaptcha from "../mixins/invisibleCaptcha";

const adultBirthDate = (value) => {
  const minVal = new Date().setFullYear(new Date().getFullYear() - 18);
  const maxVal = new Date().setFullYear(new Date().getFullYear() - 99);
  return value > minVal || value < maxVal;
};

const availableDate = (value) => {
    if (helpers.req(value)) {
      if (!(value.length < 10)) {
        let formatDate = value.split('-');
        formatDate = [formatDate[1], formatDate[0], formatDate[2]];
        return !adultBirthDate(new Date(formatDate.join('-'))) &&
          !isNaN(new Date(new Date(formatDate.join('/'))).getTime());
      } else return false;
    } else return false;
};

export default {
  name: "SignUpStep1",
  mixins: [invisibleCaptcha],
  components: {
    RadioInput,
    CheckInput,
    TextInput,
    //SignInput,
    CaptchaInput,
    DatePicker,
  },
  directives: {
      mask: VueMaskDirective
  },
  data() {
    return {
      dateFocus: false,
      verifyPersonError: false,
      registerSuccess: false,
      form: {
        lastName: '',
        firstName: '',
        middleName: '',
        birthDate: '',
        mobilePhone: '',
        sex: '',
        //personalDataAgreementSignature: '',
        personalDataConsent: false,
        newsletterConsent: false,
        captcha: {
          sid: '',
          code: '',
        },
        utm: {},
      }
    };
  },
  computed: {
    adultBirthDateInit() {
      const date = new Date();
      date.setFullYear(date.getFullYear() - 18);
      return date;
    }
  },
  validations() {
    const form = {
      lastName: {required},
      firstName: {required},
      middleName: {required},
      birthDate: {
        required,
        availableDate,
      },
      mobilePhone: {required},
      sex: {required},
      personalDataAgreementSignature: {},
      personalDataConsent: { checked: value => value === true },
      newsletterConsent: {},
    }
    this.addCaptchaValidationIfNeeded(form);
    return { form };
  },
  mounted: function () {
      let fields = document.querySelectorAll('.text-input__cyrillic');

      fields.forEach(item => {
        item.addEventListener('keypress', function (e) {
          const cyrillicPattern = /[\u0400-\u04FF]/;
          if (!cyrillicPattern.test(e.key)) e.preventDefault();
        })
      });

  },
  methods: {
    async submit() {
      // implementing invisible captcha
      await this.updateCaptchaOnSubmit();

      this.$v.form.$touch();

      if (!this.$v.form.$pending && !this.$v.form.$invalid) {
        this.$store.dispatch('showLoader');

        const params = this.getUrlParams();
        let utm = {}
        let flag = false
        for (let item in params) {
          if (['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'].indexOf(item) !== -1) {
            utm[item] = params[item];
            flag = true;
          }
        }

        if (flag) {
          this.$set(this.form, 'utm', utm)
        }

        try {
          // Регистрируем пользователя в Битриксе и в Mindbox
          let result;
          if (!this.registerSuccess) {
            result = await api.registerStep1(this.form);
            this.registerSuccess = true;
          }

          // Отправляем запрос в IDX
          result = await api.verifyPerson();
          // TODO UI сообщить о блокировке
          if (!result || result.status === "error") {
            this.verifyPersonError = true;
            return;
          }

          this.$emit('complete', result, this.form.mobilePhone);
          this.$store.dispatch('hideErrorsBag');
        } catch (e) {
          this.$store.dispatch('setErrors', e);
          await this.updateCaptchaOnError();
        } finally {
          this.$store.dispatch('hideLoader');
        }
      }
    },
    adultBirthDate(value) {
      return adultBirthDate(value);
    },
    getUrlParams() {
      const url = window.location
      let params = {};

      new URL(url).searchParams.forEach(function (val, key) {
        // Проверяем параметр на undefined
        if (params[key] !== undefined) {
          // Проверяем, имеется ли в объекте аналогичный params[key]
          // Если его нет, то добавляем его в объект
          if (!Array.isArray(params[key])) {
            params[key] = [params[key]];
          }
          params[key].push(val);
        } else {
          params[key] = val;
        }
      });
      return params;
    }
  }
}
</script>

<style lang="scss">
  @import "../scss/base/_settings.scss";
  .form {
    &__actions {
      margin-top: 32px;
    }
    &__description_align-center {
      @include mobile {
        text-align: left;
      }
    }
    &__nav {
      @include mobile {
        flex-direction: column;
        justify-content: center;
        gap: 6px;
        .form__link {
          margin-bottom: 0 !important;
        }
      }
    }
  }
</style>
<style lang="scss" scoped>
@import "../scss/base/_settings.scss";
.sign-up-error {
  margin-top: -10px;
  margin-bottom: -15px;
  @include mobile {
    margin: 0;
  }
  .form__title {
    margin-bottom: 34px;
  }
  .form__description {
    font-size: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
