import axios from "axios";
import {MyError} from "../utils";

const dadataApi = axios.create({
  baseURL: 'https://suggestions.dadata.ru',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': 'Token 44f7453167d491b6b74dec9c95bc71f66de244cf'
  },
});

class DADATA {
  static getAddress(data) {
    return DADATA._request('address', data);
  }
  static getCompany(data) {
    return DADATA._request('party', data);
  }
  static getCountry(data) {
    return DADATA._request('country', data);
  }
  static getBank(data) {
    return DADATA._request('bank', data);
  }

  static _request(path, data) {
    return dadataApi({
      url: `/suggestions/api/4_1/rs/suggest/${path}`,
      method: 'post',
      ...data,
    });
  }
}

async function changePhone(phone) {
  const options = {
    method: 'POST',
    url: '/api/changePhone',
    data: {
      phone
    }
  };

  const { data } = await axios(options);

  if (data.status === 'success') {
    return data.data;
  } else {
    throw new MyError(data.errors);
  }
}

async function verifyPhoneByCall(phone) {
  const options = {
    method: 'POST',
    url: '/api/verifyPhoneByCall',
    data: {
      phone
    }
  };

  const { data } = await axios(options);

  if (data.status === 'success') {
    return data.data;
  } else {
    throw new MyError(data.errors);
  }
}

async function verifyPhoneByCallCheck(callId) {
  const options = {
    method: 'POST',
    url: '/api/verifyPhoneByCallCheck',
    data: {
      callId
    }
  };

  const { data } = await axios(options);

  if (data.status === 'success') {
    return data.data;
  } else {
    throw new MyError(data.errors);
  }
}

async function changeEmail(email) {
  const options = {
    method: 'POST',
    url: '/api/changeEmail',
    data: {
      email
    }
  };

  const { data } = await axios(options);

  if (data.status === 'success') {
    return data.data;
  } else {
    throw new MyError(data.errors);
  }
}

async function changePassword(login) {
  const options = {
    method: 'POST',
    url: '/api/changePassword',
    data: login
  };

  const response = await axios(options);

  if (response.data.status === 'success') {
    return response.data.data;
  } else {
    throw new MyError(response.data.errors);
  }
}

async function feedback(data) {
  const formData = new FormData();
  data.files.forEach((file, index) => {
    const label = 'file' + (index ? index + 1 : '');
    formData.append(label, file);
  });
  formData.append('email', data.email);
  formData.append('name', data.name);
  formData.append('subject', data.subject);
  formData.append('message', data.message);
  formData.append('captcha[sid]', data.captcha.sid);
  formData.append('captcha[code]', data.captcha.code);
  formData.append('personalDataConsent', data.personalDataConsent);
  const options = {
    method: 'POST',
    url: '/api/feedback',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  };

  const response = await axios(options);

  if (response.data.status === 'success') {
    return response.data.data;
  } else {
    throw new MyError(response.data.errors);
  }
}

async function login(credentials) {
  const options = {
    method: 'POST',
    url: '/api/login',
    data: credentials
  };

  const { data } = await axios(options);

  if (data.status === 'success') {
    return data.data;
  } else {
    throw new MyError(data.errors);
  }
}

async function logout(url) {
  try {
    const options = {
      method: "GET",
      url: url
    };

    await axios(options);

  } catch (e) {
    alert(e);
  }
}

async function fetchProfile() {
  try {
    const options = {
      method: "GET",
      url: '/api/fetchProfile'
    };

    const {data} = await axios(options);

    if (data.status === 'success') {
      return data.data;
    }

    console.error('can\'t fetch profile', data.errors);
    return false;
  } catch (e) {
    if (e.response.status === 403) {
      console.log('not authorized');
    } else {
      console.log(e)
    }
    return false;
  }
}

async function checkAuthentication() {
  try {
    const options = {
      method: 'POST',
      url: '/api/checkAuthentication'
    };

    const response = await axios(options);

    if (response.data.status === 'success') {
      return response.data.data;
    } else {
      return false;
    }
  } catch (e) {
    alert(e);
    return false;
  }
}

async function fetchProducts() {
  try {
    const options = {
      method: "GET",
      url: '/api/products',
    };

    const response = await axios(options);

    if (response.data.status === 'success') {
      return response.data.data;
    }
  } catch (e) {
    alert(e);
  }
}

async function fetchProductsDetail(id) {
  try {
    const options = {
      method: "GET",
      url: `/api/products/${id}`,
    };

    const response = await axios(options);

    if (response.data.status === 'success') {
      return response.data.data;
    }
  } catch (e) {
    alert(e);
  }
}

async function fetchArticleDetail(code) {
  try {
    const options = {
      method: "GET",
      url: `/api/articles/${code}`,
    };

    const response = await axios(options);

    if (response.data.status === 'success') {
      return response.data.data;
    }
  } catch (e) {
    alert(e);
  }
}

async function getCaptchaCode() {
  try {
    const options = {
      method: 'POST',
      url: `/api/getCaptchaCode`,
    };

    const response = await axios(options);

    if (response.data.status === 'success') {
      return response.data.data;
    }
  } catch (e) {
    alert(e);
  }
}

async function registerStep1(input) {
  const options = {
    method: 'POST',
    url: '/api/registerStep1',
    data: input
  };

  const {data} = await axios(options);

  if (data.status !== 'success') {
    throw new MyError(data.errors);
  }

  return data.data;
}

async function registerStep2(iteration, file1, file2) {
  const formData = new FormData;
  formData.append('file1', file1);
  formData.append('file2', file2);
  formData.append('iteration', iteration);

  const options = {
    method: 'POST',
    url: '/api/registerStep2',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  };

  const {data} = await axios(options);

  if (data.status !== 'success') {
    throw new MyError(data.errors);
  }

  return true;
}

async function updateProfileNew(profile) {
  const formData = new FormData;
  formData.append('lastName', profile.lastName);
  formData.append('firstName', profile.firstName);
  formData.append('middleName', profile.middleName);
  formData.append('birthDate', profile.birthDate);
  formData.append('file1', profile.passport);

  const options = {
    method: 'POST',
    url: '/api/updateProfileNew',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  };

  const {data} = await axios(options);

  if (data.status !== 'success') {
    throw new MyError(data.errors);
  }

  return true;
}

async function verifyAge(profile) {
  const formData = new FormData;
  formData.append('lastName', profile.lastName);
  formData.append('firstName', profile.firstName);
  formData.append('middleName', profile.middleName);
  formData.append('birthDate', profile.birthDate);

  const options = {
    method: 'POST',
    url: '/api/verifyAge',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  };

  const {data} = await axios(options);

  if (data.status !== 'success') {
    throw new MyError(data.errors);
  }

  return data.data;
}

async function updateCustomFields({
                                    passportWasCheck,
                                    idxCheckFullName,
                                    idxAdditionalPersonalDataCheck,
                                    pDataAgreementSignature,
                                  }) {
  const formData = new FormData;

  if (typeof passportWasCheck !== 'undefined') {
    formData.append('fields[passportWasCheck]', passportWasCheck ? 'Y' : 'N');
  }

  if (typeof idxCheckFullName !== 'undefined') {
    formData.append('fields[idxCheckFullName]', idxCheckFullName ? 'Y' : 'N');
  }

  if (typeof idxAdditionalPersonalDataCheck !== 'undefined') {
    formData.append('fields[idxAdditionalPersonalDataCheck]', idxAdditionalPersonalDataCheck ? 'Y' : 'N');
  }

  if (typeof pDataAgreementSignature !== 'undefined') {
    formData.append('fields[pDataAgreementSignature]', pDataAgreementSignature);
  }

  const options = {
    method: 'POST',
    url: '/api/updateCustomFields',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const {data} = await axios(options);

  if (data.status !== 'success') {
    throw new MyError(data.errors);
  }

  return data.data;
}

async function updateDocuments(form) {
  const formData = new FormData;
  formData.append('file1', form.file1);
  formData.append('file2', form.file2);

  const options = {
    method: 'POST',
    url: '/api/updateDocuments',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  };

  const {data} = await axios(options);

  if (data.status !== 'success') {
    throw new MyError(data.errors);
  }

  return data.data;
}

async function updateInn(inn) {
  const options = {
    method: 'POST',
    url: '/api/updateInn',
    data: { inn }
  };

  const {data} = await axios(options);

  if (data.status !== 'success') {
    throw new MyError(data.errors);
  }

  return data.data;
}

async function registerStep4(input) {
  const options = {
    method: 'POST',
    url: '/api/registerStep4',
    data: input
  };

  const {data} = await axios(options);

  if (data.status !== 'success') {
    throw new MyError(data.errors);
  }

  return data.data;
}

async function registerStep6(input) {
  const options = {
    method: 'POST',
    url: '/api/registerStep6',
    data: input
  };

  const {data} = await axios(options);

  if (data.status !== 'success') {
    throw new MyError(data.errors);
  }

  return data.data;
}

async function sendPhoneConfirmationCode() {
  const options = {
    method: 'POST',
    url: '/api/sendPhoneConfirmationCode',
  };

  const {data} = await axios(options);

  if (data.status !== 'success') {
    throw new MyError(data.errors);
  }

  return data.data;
}

async function fetchCigaretteMarks() {
  try {
    const {data} = await axios.get('/api/fetchCigaretteMarks');

    if (data.status === 'success') {
      return data.data;
    }
  } catch (e) {
    alert(e);
  }
}

async function fetchCaptchaCode() {
  try {
    const response = await axios.post('/api/getCaptchaCode');
    if (response.data.status !== 'success') {
      throw new Error('Failed fetch captcha code');
    }
    if (response.data.status === 'success') {
      return response.data.data;
    }
  } catch (e) {
    alert(e);
  }
}

async function verifyPerson() {
    const { data } = await axios.post('/api/verifyPerson');

    return data;
}

async function verifyPersonNew(profile) {
    const formData = new FormData;
    formData.append('lastName', profile.lastName);
    formData.append('firstName', profile.firstName);
    formData.append('middleName', profile.middleName);
    formData.append('birthDate', profile.birthDate);
    formData.append('file1', profile.passport);

    const options = {
      method: 'POST',
      url: '/api/verifyPersonNew',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    };

    const {data} = await axios(options);

    if (data.status !== 'success') {
      throw new MyError(data.errors);
    }

    return true;
}

async function verifyPhoneCode(code) {
    const { data } = await axios.post('/api/verifyPhoneCode', {
      code: code
    });

    return data.status === 'success' && data.data
}

async function smsCodeAuthentication(form) {
  const options = {
    method: 'POST',
    url: '/api/smsCodeAuthentication',
    data: form
  };

  const response = await axios(options);

  if (response.data.status === 'success') {
    return response.data.data;
  } else {
    throw new MyError(response.data.errors);
  }
}

async function simplePostRequest(url, data) {
  const options = {
    method: 'POST',
    url: url,
    data: data
  };

  const response = await axios(options);

  if (response.data.status === 'success') {
    return response.data.data;
  } else {
    throw new MyError(response.data.errors);
  }
}

async function simplePostSuccess(url, data) {
  const options = {
    method: 'POST',
    url: url,
    data: data
  };

  const response = await axios(options);

  if (response.data.status === 'success') {
    return true;
  } else {
    throw new MyError(response.data.errors);
  }
}

async function findAddress(token, fiasId, type) {
  const options = {
    url: "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/address",
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
      "Authorization": "Token " + token
    },
    data: {query: fiasId}
  }

  const { data } = await axios(options);

  if (
    typeof data.suggestions !== 'undefined'
    && data.suggestions[0] !== 'undefinded'
    && data.suggestions[0].value !== 'undefinded'
  ) {
    if (type) {
      return data.suggestions[0].data[type];
    } else {
      return data.suggestions[0].value;
    }
  }

  throw new MyError(data.errors);
}

async function sendConsumptionFirstQuestion(answer) {
  const options = {
    url: '/api/sendConsumptionFirstQuestion',
    method: 'POST',
    data: {
      answer: answer
    }
  };

  const { data } = await axios(options);

  return data.status === 'success';
}

async function sendConsumptionSecondQuestion(answer) {
  const options = {
    url: '/api/sendConsumptionSecondQuestion',
    method: 'POST',
    data: {
      primaryCigaretteSubfamily: answer.primaryCigaretteSubfamily,
      primaryBrandAgeField: answer.primaryBrandAgeField,
      primaryBrandShareField: answer.primaryBrandShareField,
      cigaretteCountPerDayField: answer.cigaretteCountPerDayField,
      secondaryCigaretteSubfamily: answer.secondaryCigaretteSubfamily,
    }
  };

  const { data } = await axios(options);

  console.log(data);
}

async function consumptionWasShow() {
  const options = {
    url: '/api/consumptionWasShow',
    method: 'POST',
  };

  await axios(options);
}

async function checkCanWeShowConsumption() {
  const options = {
    url: '/api/checkCanWeShowConsumption',
    method: 'POST',
  };

  const {data} = await axios(options);

  return data.can;
}

async function sendFinishMaterial(id) {
  const options = {
    url: '/api/finishMaterial',
    method: 'POST',
    data: {
      materialId: id
    }
  };

  const { data } = await axios(options);

  return data.status === 'success';
}

async function getFinishStats() {
  const options = {
    url: '/api/getFinishStats',
    method: 'GET',
  };

  const { data } = await axios(options);

  return data.data;
}

async function fetch(url, data = {}, method = 'POST') {
  const options = {
    method: method,
    url: url,
    data: data,
  };

  return axios(options);
}

async function fetchWithAlert(url, data = {}, method = 'GET') {
  try {
    const response = await fetch(url, data, method);

    if (response.data.status === 'success') {
      return response.data.data;
    }
  } catch (e) {
    alert(e);
  }
}

async function fetchSurveys(data) {
  return fetchWithAlert('/api/surveys', data, 'POST');
}

async function fetchPortalListItems(route, data) {
  return fetchWithAlert(route, data, 'POST');
}

async function fetchSurveyDetail(code) {
  return fetchWithAlert(`/api/surveys/${code}`);
}

async function fetchFileData(code) {
  return fetchWithAlert(`/api/filedata/${code}`);
}

async function fetchNonogramDetail(code) {
  return fetchWithAlert(`/api/nonograms/${code}`);
}

async function fetchThemes(data) {
  return fetchWithAlert('/api/getThemes', data, 'POST');
}

export default {
  changePhone,
  changeEmail,
  verifyPhoneByCall,
  verifyPhoneByCallCheck,
  changePassword,
  feedback,
  login,
  logout,
  fetchProfile,
  checkAuthentication,
  fetchProducts,
  fetchProductsDetail,
  fetchArticleDetail,
  getCaptchaCode,
  registerStep1,
  registerStep2,
  registerStep4,
  registerStep6,
  fetchCigaretteMarks,
  fetchCaptchaCode,
  sendPhoneConfirmationCode,
  verifyPerson,
  verifyPhoneCode,
  smsCodeAuthentication,
  simplePostRequest,
  simplePostSuccess,
  findAddress,
  updateDocuments,
  updateInn,
  sendConsumptionFirstQuestion,
  sendConsumptionSecondQuestion,
  consumptionWasShow,
  checkCanWeShowConsumption,
  sendFinishMaterial,
  fetchSurveys,
  fetchSurveyDetail,
  fetchPortalListItems,
  DADATA,
  updateProfileNew,
  verifyPersonNew,
  verifyAge,
  updateCustomFields,
  fetchFileData,
  fetchNonogramDetail,
  fetchThemes,
  getFinishStats,
}
