<template>
  <form @submit.prevent="submit">
    <h2 class="form__title">Регистрация</h2>
    <h3 class="form__subtitle">4 шаг. Контакты</h3>
    <div class="form__description form__description_align-center">
      <p>Заполните и подтвердите электронную почту</p>
    </div>
    <div class="form-row form-row_no-flex">
      <text-input
        label="Ваша электронная почта*"
        name="email"
        autocomplete="off"
        v-model="$v.form.email.$model"
        :error="$v.form.email.$error || errorEmailUsed()"
      >
        <template #errors>
          <div class="text-input__error" v-if="$v.form.email.$error || errorEmailUsed()">
            <div v-if="!$v.form.email.required">Заполните поле</div>
            <div v-else-if="!$v.form.email.email">Неправильный электронная почта</div>
            <div v-else-if="errorEmailUsed()">Эта электронная почта уже используется. Укажите другой адрес электронной почты или попробуйте восстановить пароль.</div>
          </div>
        </template>
      </text-input>
    </div>
    <captcha-input
      v-if="showCaptcha"
      ref="captchaInput"
      v-model="$v.form.captcha.$model"
      :error="$v.form.captcha.$error"
      :sid="captchaSid"
      @requestUpdate="updateCaptchaOnRequest"
    >
      <template #errors>
        <div class="text-input__error" v-if="$v.form.captcha.$error">
          <div v-if="!$v.form.captcha.required">Заполните поле</div>
        </div>
      </template>
    </captcha-input>
    <div class="form-row form-row_mobile-wrap">
      <div class="form__nav">
        <router-link v-if="!$store.state.currentUser" :to="{name: 'login'}" class="form__link">Войти</router-link>
        <router-link :to="{ name: 'feedback' }" class="form__link">Написать нам</router-link>
        <delete-questionnaire-btn />
      </div>
      <button class="form__submit button button__lg button__blue" type="submit">Далее</button>
    </div>
  </form>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import TextInput from './TextInput';
import CaptchaInput from "./CaptchaInput";
import api from "../services/api";
import invisibleCaptcha from "../mixins/invisibleCaptcha";

export default {
  name: "SignUpStep4",
  mixins: [invisibleCaptcha],
  components: {
    TextInput,
    CaptchaInput,
  },
  data() {
    return {
      form: {
        email: '',
        captcha: {
          sid: '',
          code: '',
        },
      },
    };
  },
  validations() {
    const form = {
      email: {required, email},
    }
    this.addCaptchaValidationIfNeeded(form);
    return { form };
  },
  methods: {
    async submit() {
      // implementing invisible captcha
      await this.updateCaptchaOnSubmit();

      this.$v.form.$touch();

      if (!this.$v.form.$pending && !this.$v.form.$invalid) {
        this.$store.dispatch('showLoader');

        try {
          const result = await api.registerStep4(this.form);

          if (result) {
            this.$emit('complete', this.form.email);
            this.$store.dispatch('hideErrorsBag');
          }
        } catch (e) {
          this.$store.dispatch('setErrors', e);
          await this.updateCaptchaOnError();
        } finally {
          this.$store.dispatch('hideLoader');
        }
      }
    },
    errorEmailUsed() {
      return this.$store.state.errors.find((item) => item === 'Email already used');
    }
  }
}
</script>
