export default function Swiper(
  area,
  isVertical
) {
  if (isVertical == undefined) {
    isVertical = false;
  }
  const clientAxis = isVertical ? "clientY" : "clientX";
  let delta;
  let v0;
  let clients = [];
  
  const setEvents = () => {
    area.addEventListener("touchstart", handleTouchStart, { passive: true });
    area.addEventListener("mousedown", handleMouseDown);
  };

  const handleTouchStart = e => {
    v0 = e.touches[0][clientAxis];
    startSwipe("touch");
  };

  const handleMouseDown = e => {
    v0 = e[clientAxis];
    startSwipe("mouse");
  };

  const startSwipe = type => {
    delta = 0;
    if (type == "touch") {
      area.addEventListener("touchcancel", handleTouchCancel);
      area.addEventListener("touchend", handleTouchEnd);
      area.addEventListener("touchmove", handleTouchMove);
    } else {
      area.addEventListener("mouseleave", handleMouseLeave);
      area.addEventListener("mousemove", handleMouseMove);
      area.addEventListener("mouseup", handleMouseUp);
    }
  };

  const handleTouchMove = e => {
    const v1 = e.touches[0][clientAxis];
    calculateDelta(v1);
    notifyClients();
    v0 = v1;
  };

  const handleMouseMove = e => {
    const v1 = e[clientAxis];
    calculateDelta(v1);
    notifyClients();
    v0 = v1;
  };

  const calculateDelta = v1 => {
    delta = v1 - v0;
  };

  const handleTouchEnd = () => {
    finishSwipe("touch");
  };

  const handleTouchCancel = () => {
    finishSwipe("touch");
  };

  const handleMouseUp = () => {
    finishSwipe("mouse");
  };

  const handleMouseLeave = () => {
    finishSwipe("mouse");
  };

  const handleFinish = () => {

  }

  const finishSwipe = type => {
    if (type == "touch") {
      area.removeEventListener("touchcancel", handleTouchCancel);
      area.removeEventListener("touchend", handleTouchEnd);
      area.removeEventListener("touchmove", handleTouchMove);
    } else {
      area.removeEventListener("mouseleave", handleMouseLeave);
      area.removeEventListener("mousemove", handleMouseMove);
      area.removeEventListener("mouseup", handleMouseUp);
    }
    handleFinish();
  };

  const notifyClients = () => {
    for (let client of clients) {
      client.receiveNotification(delta, 'swiper');
    }
  }

  this.addClient = function(client) {
    clients.push(client);
  }

  setEvents();
}
